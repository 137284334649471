import React, { useState, useEffect } from "react";

import { getsitedata } from "./../action/user";
function Footer() {

    const [sites, setSites] = useState([])
    const getPieData = async () => {
        var test = await getsitedata();
        setSites(test.userValue[0]);
        //   console.log(test, "bird");
    }

    useEffect(() => {
        //logout(history)
        getPieData();
    }, []);

    return (
        <div>
            <div className="footer">
                <div className="container footer-info">
                    <div className="footer-info w3-agileits-info row">
                        <div className="col-md-4 address-left agileinfo">
                            {/* <span>  <img src={require('../../assests/images/malligai.png') }/></span> */}
                            <ul>
                                <p>திண்டுக்கல்லை தலைமையிடமாகக் கொண்டு செயல்படும் எங்கள் ஸ்ரீ  மல்லிகை திருமண தகவல் மையம் அரசால் அங்கீகரிக்கப்பட்டு உள்ளது.</p><br />
                                <p> ஸ்ரீ மல்லிகை திருமண தகவல்  மையத்தில் உங்களது மகள்/மகன் ஜாதகங்களை இலவசமாக பதிவு செய்யுங்கள்.
                                    {/* <label  style={{ color: '#0fc10f', marginLeft: '11px', fontSize: '14px' }}>ReadMore &gt;</label> */}

                                </p>
                            </ul>
                        </div>
                        <div className="col-md-4 footer-grids">
                            <h3>தொடர்பு கொள்ள</h3>
                            <ul class="facility-list">
                                <li class="facility-list__item">
                                    <div class="icon-circle">
                                        <i class="fa-solid fa-address-book"></i>
                                    </div>
                                    <p class="address-footer">
                                        {/* {sites.address} */}
                                        ஸ்ரீ மல்லிகை திருமண தகவல் மையம்,<br></br>
                                        No.22, மெங்கில்ஸ் ரோடு,<br></br>நாகல் நகர், திண்டுக்கல் -624003.
                                    </p>
                                </li>
                                <li class="facility-list__item">
                                    <div class="icon-circle">
                                        <i class="fa-solid fa-phone"></i>
                                    </div>
                                    <p style={{ fontSize: "17px", fontWeight: 800 }}>{sites.phoneNo}</p>
                                </li>
                                
                                {/* <li class="facility-list__item">
                                    <div class="icon-circle">
                                        <i class="fa-solid fa-at"></i>
                                    </div>
                                    <p>{sites.email}</p>
                                </li> */}
                            </ul>
                            {/* <ul>
                            <li><img src={ require('../../assests/images/locate.png') } /><span>{sites.address}</span></li>
                            <li><img src={ require('../../assests/images/phone.png') } /><span>{sites.phoneNo}</span></li>
                            <li><img src={ require('../../assests/images/mail.png') } /><span>{sites.email}</span></li>
                        </ul> */}
                        </div>
                        <div className="col-md-4 footer-grids quick">
                            <h3>இணைப்பு</h3>
                            <ul className="qik-lnk">
                                <li><a href="/" style={{ fontWeight: '700' }}>முகப்பு</a></li>
                                <li><a href='/about'  style={{ fontWeight: '700' }}>எங்களை பற்றி</a></li>
                                {/* <li><a href='/services'>சேவைகள்</a></li> */}
                                <li><a href='/contact' style={{ fontWeight: '700' }}>தொடர்பு கொள்ள</a></li>
                                {/* <li><a href="pages/modal.js" data-target="#myModal" data-toggle="modal" onclick="$('#mysoul').trigger('click')">Free Search</a></li> */}
                                <li><a href='/payment' style={{ fontWeight: '700' }} >உறுப்பினர் திட்டம்</a></li>
                            </ul>
                            {/* <div className="social">
                            <ul className="list-inline">
                                <li><a  href="https://www.facebook.com/Thirumagalmatrimony-249442865999426" target="_blank"><img src={ require('../../assests/images/fb.png') } /></a></li>
                                <li><a  href="https://twitter.com/thirumagalmatri" target="_blank"><img src={ require('../../assests/images/tr.png') } /></a></li>
                            </ul>
                        </div> */}
                        </div>
                        {/* <div className="col-md-3 footer-grids quick">
                            <h3>நிபந்தனைகள்</h3>
                            <ul className="qik-lnk">
                                <li><a href='/terms'>Terms &amp; Conditions </a></li>
                                <li><a href='/privacy' >Privacy Policy</a></li>
                                <li><a href='/cancellation' >Cancellation and Refund Policy</a></li>

                            </ul>
                            <div className="social">
                                <ul className="list-inline">
                                    <li><a href="https://www.facebook.com/Thirumagalmatrimony-249442865999426" target="_blank"><img src={require('../../assests/images/fac1.png')} /></a></li>
                                    /<li><a href="https://twitter.com/thirumagalmatri" target="_blank"><img src={require('../../assests/images/twit1.png')} /></a></li>
                                </ul>
                            </div>
                        </div> */}


                        <div className="clearfix" />
                        {/*                </div>*/}
                        <div className="clearfix" />
                    </div>
                </div>
            </div>
            <div className="copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p style={{ wordSpacing: '1px' }}><img style={{ height: '20px' }} src={require('../../assests/images/copyright.png')} /> 2024 Srimalligai Matrimony. All rights reserved.</p>
                        </div>
                        <div className="col-md-offset-1 col-md-5" style={{ textAlign: 'right' }}>
                            {/* <p><a href='#'style={{ wordSpacing: '5px' }}>விதிமுறை &amp; நிபந்தனைகளும் </a> <span style={{ marginLeft: '20px', marginRight: '20px' }}> | </span>  <a href='/privacy' style={{ wordSpacing: '10px' }}>தனியுரிமைக் கொள்கை</a></p> */}
                            {/* <a href="https://www.rayaztech.com/" target="_blank"><p className='abcd'>Design & Developed By RAYAZ TECHNOLOGIES <img src={require('../../assests/images/looogo.png')} /></p></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default Footer;