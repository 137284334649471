
import React, { useEffect, useState } from 'react';
import './spiderman.css'
import FavoriteIcon from '@mui/icons-material/Favorite';
// import logo from '../../assests/images/profile.jpg'

// import { getregister, getregisterfilter , getcaste } from "./../action/user";

import { getmyprofile, userpackagedetail } from "./../action/user";
function datealignment(startdate){
   
    var now = new Date(startdate);

var startdate = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear() + " " ;

   
      
// Regular expression to identify HTML tags in 
// the input string. Replacing the identified 
// HTML tag with a null string.
return startdate;
}

function Freesearch() {

    const [showPopup, setShowPopup] = useState(false);

    const [post, setRegister] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [height1, setHeight] = useState(window.innerHeight);
    const [userpackages, setuserpackages] = useState(null);
    // const [registerfilter, setRegisterfilters]=useState([]);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {

        // console.log(width);

        // console.log(height1);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    });

    const togglePopup = () => {
        // Pop up 의 open/close 상태에 따라

        setShowPopup(!showPopup);
    };

    const closeModal = (e) => {

        // console.log(e.target.className);
        if (e.target.className === "popup") {
            togglePopup();
        }
    };

    const getUserData = async () => {
        var test = await getmyprofile();

        // console.log(test,"gfdfdssfgds");
        setRegister(test.userValue);


        var userdetail = await userpackagedetail();
        console.log(userdetail.success, "twinkle");
        // console.log(userdetail,"userdetailuserdetailuserdetailuserdetail")
        if (userdetail.success) {
            setuserpackages(userdetail.userValue)
        }
    }



    // console.log(userpackages,"userpackagesuserpackagesuserpackages")


    useEffect(() => {
        //logout(history)
        getUserData();
    }, []);


    // const getUserData = async () => {
    //     // 
    // console.log(filterobj,"fghjfghjfgdjhg");post
    //   var test =  await getregisterfilter();
    //   setRegisterfilters(test.userValue);
    //   
    // console.log(test, "win");

    //   }
    //   useEffect(() => {
    //     //logout(history)
    //     getUserData();
    //   }, []);



    return (

        <div className="container">
















            <div className="centerFruit" >

                <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>
                    <div className="col-md-12 sm-12 ">

                        <p className='name1'>Package Details</p>

                        {userpackages ? <div className="row " >

                            <div className="col-md-12 ">

                                <table className='Boomer' >


                                    <tbody><tr>
                                        <td className='billa'>தற்போதைய தொகுப்புகள்</td>
                                        <td className='kuruvi'>:</td>
                                        <td className='alan'>{userpackages.package}
                                            {/* { (localStorage.user_token) ? (<><a href='/myprofile' style={{ color:"white", fontSize:"18px", borderRadius:"6px", backgroundColor:"#b91d97", padding:"7px 9px 12px 9px"}}>My profile</a><a   onClick={onlogout} style={{textTransform:'none', cursor:"pointer", color:"#b91d97", fontSize:"22px", marginLeft:"9px", fontWeight:"800"}}><img style={{ marginTop:"-7px", marginLeft:"8px", maxWidth:"24px"}} src={ require('../../assests/images/logout.png') } /> Logout</a></>) : (<><a href='/registration' style={{ color:"white", fontSize:"18px", borderRadius:"6px", backgroundColor:"#b91d97", padding:"7px 9px 12px 9px", marginLeft:"8px"}}>Registration</a> <a   onClick={handleOpen} style={{textTransform:'none', cursor:"pointer", color:"#b91d97", fontSize:"22px", marginLeft:"9px", fontWeight:"700"}}> <img style={{ marginTop:"-7px", marginLeft:"8px", maxWidth:"18px"}} src={ require('../../assests/images/person.png') } /> Login</a></>)} */}
                                        </td>
                                    </tr>
                                        <tr>
                                            <td className='billa'>மொத்த எண்ணிக்கை</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{userpackages.totalcount}</td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>செல்லுபடியாகும் தேதி</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{datealignment(userpackages.expiredate)}</td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>பார்க்கப்பட்ட எண்ணிக்கை</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'> {userpackages.viewedcount} </td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>மீதமுள்ள எண்ணிக்கை</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'> {userpackages.remainingcount} </td>
                                        </tr>

                                    </tbody></table>
                            </div>


                        </div> : <p style={{ fontSize: "15px" }}>No Package Available</p>}
                    </div>
                </div>
            </div>










        </div>











    )
}

export default Freesearch;