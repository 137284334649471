
import React,{useEffect, useState} from 'react';
import { getregister, getviewedprofiles , getcaste } from "./../action/user";
import {useLocation} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import FavoriteIcon from '@mui/icons-material/Favorite';

import config from "../../lib/config";
const initialFormValue = {
   
    sex: "",

};
function Freesearch() {

    const [registerfilter, setRegisterfilters]=useState([]);
    const [formValue, setFormValue] = useState(initialFormValue);
    const [width, setWidth] = useState(window.innerWidth);
    const [height1, setHeight] = useState(window.innerHeight);
    const sexch = [
        { title: "Male", value: "Male" },
        { title: "Female", value: "Female" },
    ];
    
    const search = useLocation().search;
    const [searchparamsf,setsearchparamsf]=useState(search);
    
    // console.log("fresdrw",search)
    var filterobj ="status=1";
    const castename = new URLSearchParams(search).get('castename');
    if(castename){
        filterobj+="&castename="+castename;
    }
    const religion = new URLSearchParams(search).get('religion');
    if(religion){
        filterobj+="&religion="+religion;
    }
    const subcaste = new URLSearchParams(search).get('subcaste');
    if(subcaste){
        filterobj+="&subcaste="+subcaste;
    }
    const sex = new URLSearchParams(search).get('sex');
    if(sex){
        filterobj+="&sex="+sex;
    }
    const maritalname = new URLSearchParams(search).get('maritalname');
    if(maritalname){
        filterobj+="&maritalname="+maritalname;
    }

    const c_date = new Date();
    let year = c_date.getFullYear();
    const age1 = new URLSearchParams(search).get('age1');
    if(age1){
       const  g_year= year - age1;
       
        filterobj+="&year[$lte]="+g_year;
    }

    const age2 = new URLSearchParams(search).get('age2');
    if(age2){
        const l_year = year - age2;
      
        filterobj+="&year[$gte]="+l_year;
    }

    // var filterarray="religion="+religion+"&subcaste="+subcaste+"&castename="+castename+"&sex="+sex+"&maritalname="+maritalname;

    
    // const subcaste = new URLSearchParams(search).get('subcaste');
    // const sex = new URLSearchParams(search).get('sex');
    // const maritalname = new URLSearchParams(search).get('maritalname');
    const [castes, setCastes]=useState([]);
    
  const getUserData = async () => {
    
    // console.log("fghjfghjfghjgjhgfjhgdfjhgdfsjhgdjdjhg");
  var test =  await getviewedprofiles();
  
//   console.log(test, "solo");
  setRegisterfilters(test.userValue.map(e=>e.viewedlist));


  }
  useEffect(() => {

    getUserData();
  }, []);



    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        
        // console.log(width);
        
        // console.log(height1);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    });


    return (
        <>
        <div className="container">
            <div className="text-center" style={{ marginTop: '20px', marginBottom: '80px'  }}>
                    <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto'}}>
                    {/* <div className="col-md-12 prink" style={{marginTop: '35px' }}>
                     <div className="row">
                     
                    
                     </div>
                     </div> */}
                    {registerfilter.length ? registerfilter.map(post=>post?(    
                        <div className="col-md-12 search-result" style={{ marginTop: '35px', background:"#bdf2c054" }}>
                        <div className="title row">
                            <div className="col-md-6" style={{ paddingLeft: 'unset', fontSize:'15px'}}>
                                <p className='name' style={{ fontSize:"16px"}}>{post.userid} <span style={{ fontSize:"18px"}}> - {post.mname}</span></p>
                            </div>
                            <div className="col-md-6" style={{ paddingRight: 'unset',fontSize: '15px'}}>
                                <a href={"/batman/"+post._id} className='buttonsss'>View Profile</a>
                            </div>
                           
                        </div>
                        <hr style={{marginTop: '-10.1px'}}/>
                        <div className="row " >
                            <div className="col-md-2" >
                               <img  src={post.photopath.length && post.photopath[0] ? config.API+"/images/user/" + post.photopath :post.sex == "Male" ? require('../../assests/images/man.png') : require('../../assests/images/woman.png')}  alt="Profile" className="img-responsive" style={{ height:"200px"}}/>

                                {/* <img  src={"http://15.206.29.17:2053/images/user/" +post.photopath}  alt="Profile" className="img-responsive" style={{ height:"200px"}}/> */}
                            </div>
                            <div className="col-md-5 content">
                                
                                <table className='Droid Sans' style={{ width: '80%', fontSize: '15px', fontFamily:'fantasy' }}>

                                    <tbody>

                                       <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left', paddingBottom:"10px" }}><b>பாலினம் </b></td>
                                            <td style={{ width: '22px' , paddingBottom:"10px"}}>:</td>
                                            <td className='treatzz' style={{ textAlign: 'left', paddingBottom:"10px" }}> {post.sex}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left' , paddingBottom:"10px"}}><b>வயது</b></td>
                                            <td style={{ width: '22px', paddingBottom:"10px"}}>:</td>
                                                <td className='treatzz' style={{ textAlign: 'left', paddingBottom:"10px" }}>{post.age =  new Date().getFullYear()- post.year + "" + "Yrs"}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left', paddingBottom:"10px"}}><b>பிறந்த தேதி</b></td>
                                            <td style={{ width: '22px' , paddingBottom:"10px"}}>:</td>
                                             <td className='treatzz' style={{ textAlign: 'left' , paddingBottom:"10px"}}> {post.dob = post.date + "-" +  post.month + "-" + post.year}</td>
                                        </tr>

                                        <tr>
                                        <td style={{ color: '#0825cb',textAlign:'left', paddingBottom:"10px" }}><b>தந்தையின் பெயர்</b></td>
                                        <td style={{ width: '22px', paddingBottom:"10px"}}>:</td>
                                                <td className='treatzz' style={{ textAlign: 'left',marginLeft:'10px' , paddingBottom:"10px"}}>{post.father_name}</td>
                                    </tr>
                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left', paddingBottom:"10px"}}><b>தாய் பெயர்</b></td>
                                            <td style={{ width: '22px', paddingBottom:"10px"}}>:</td>
                                                <td className='treatzz' style={{ textAlign: 'left', paddingBottom:"10px" }}>{post.mother_name}</td>
                                        </tr>
                                       
                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left', paddingBottom:"10px"}}><b>சாதி</b></td>
                                            <td style={{ width: '22px', paddingBottom:"10px"}}>:</td>
                                                <td className='treatzz' style={{ textAlign: 'left' , paddingBottom:"10px"}}>{post.castename}</td>
                                        </tr>
                                        {/* <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left', paddingBottom:"10px" }}><b>நட்சத்திரம் </b></td>
                                            <td style={{ width: '22px' , paddingBottom:"10px"}}>:</td>
                                            <td style={{ textAlign: 'left', paddingBottom:"10px" }}> {post.starname}</td>
                                        </tr> */}
                                    </tbody></table>
                                   
                            </div>
                            <div className="col-md-5 content">
                                <table style={{ width: '80%', fontSize: '15px' }}>
                                    <tbody><tr>
                                        <td style={{ color: '#0825cb', textAlign: 'left' , paddingBottom:"10px"}}><b>மதம்</b></td>
                                        <td style={{ width: '22px', paddingBottom:"10px" }}>:</td>
                                            <td className='treatzz' style={{ textAlign: 'left' , paddingBottom:"10px"}}>{post.religion}</td>
                                    </tr>
                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left', paddingBottom:"10px" }}><b>கல்வி</b></td>
                                            <td style={{ width: '22px', paddingBottom:"10px" }}>:</td>
                                                <td className='treatzz'  style={{ textAlign: 'left' , paddingBottom:"10px"}}>{post.educ}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left', paddingBottom:"10px" }}><b>தொழில்</b></td>
                                            <td style={{ width: '22px', paddingBottom:"10px"}}>:</td>
                                                <td className='treatzz' style={{ textAlign: 'left' , paddingBottom:"10px"}}>{post.w_place}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left', paddingBottom:"10px"}}><b>உயரம்</b></td>
                                            <td style={{ width: '22px', paddingBottom:"10px"}}>:</td>
                                            <td className='treatzz'  style={{ textAlign: 'left', paddingBottom:"10px" }}>{post.height}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left' , paddingBottom:"10px"}}><b>நகரம் </b></td>
                                            <td style={{ width: '22px', paddingBottom:"10px"}}>:</td>
                                                <td className='treatzz' style={{ textAlign: 'left', paddingBottom:"10px" }}> {post.city} </td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: '#0825cb', textAlign: 'left' , paddingBottom:"10px"}}><b>உட்பிரிவு </b></td>
                                            <td style={{ width: '22px' , paddingBottom:"10px"}}>:</td>
                                                <td className='treatzz' style={{ textAlign: 'left', paddingBottom:"10px" }}> {post.subcaste}</td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                                </div>
                                
                        </div>
                    </div>
        
                    ):null):null}
                    </div></div>
</div>
        
            
            
            
         
     
       
        </>
    )
}

export default Freesearch;
