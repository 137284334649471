export const MenuItems = [{
        title: 'Home',
        url: '/',
        cName: 'nav-link'
    },
    {
        title: 'About Us',
        url: '/about',
        cName: 'nav-link'
    },
    {
        title: 'Free Search',
        url: '/freesearch',
        cName: 'nav-link'
    },
    {
        title: 'Contact Us',
        url: '/contact',
        cName: 'nav-link'
    },
    // {
    //     title: 'Registration',
    //     url: '/registration',
    //     cName: 'nav-link'
    // },
    {
        title: 'Membership Plan',
        url: '/payment',
        cName: 'nav-link'
    },
    {
        title: 'Payment Plan',
        url: '/plandetail',
        cName: 'nav-link'
    },
    // {
    //     title: 'Member',
    //     url: '/member',
    //     cName: 'nav-link'
    // },
    // {
    //     title: 'Sign-up',
    //     url: '#',
    //     cName: 'nav-link-mobile'
    // },
];