import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import MyReactImageMagnify from '../../components/Imagegallery/MyImageGallery';

class MyImageGallery extends Component {
  myRenderItem = (pic) => {
    return <MyReactImageMagnify selectedPic={pic} {...this.props} />;
  };

  render() {
    const properties = {
      thumbnailPosition: "left",
      showNav: true,
      useBrowserFullscreen: true,
      showPlayButton: false,
    //   renderItem: (pic) => this.myRenderItem(pic),
      items:this.props.images
    };

    return <ImageGallery {...properties} />;
  }
}

export default MyImageGallery;
