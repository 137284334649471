import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';  
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import About from './components/pages/About';
import Registration from './components/pages/registration';
import Homepage from './components/pages/Homepage';
import Freesearch from './components/pages/freesearch';
import Cancellation from './components/pages/cancellation'
import Terms from './components/pages/terms';

import Contact from './components/pages/contact';
import Payment from './components/pages/payment';
import Plan from './components/pages/plandetail';
import Member from './components/pages/member';
import Footer from './components/pages/footer';
import Header from './components/pages/header';
import Services from './components/pages/services';
import Modal from './components/pages/modal';
import Privacy from './components/pages/Privacy';
import Batman from './components/pages/batman';
import Edit from './components/pages/editregistration'
import Myprofile from './components/sidebar/myprofile'
import Viewed from './components/sidebar/table3'
import Membership from './components/sidebar/membership'
import ShowCard from './components/sidebar/showCard'
import Saved from './components/sidebar/saved'
import AnotherViewed from './components/sidebar/anotherProfile'
import Otp from './ui/otp'
import { SignalCellularNullSharp } from '@material-ui/icons';
import SMSPage from './components/pages/smspage';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header/>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path='/about' element={<About />} />
          <Route path='/abouts' element={<About />} />
          <Route path='/registration' element={<Registration />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/contacts' element={<Contact />} />
          <Route path='/freesearch' element={<Freesearch />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/plandetail' element={<Plan />} />
          <Route path='/member' element={<Member />} />
          <Route path='/services' element={<Services />} />
          <Route path='/modal' element={<Modal />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/batman/:id' element={<Batman />} />
          <Route path='/myprofile' element={<Myprofile />} />
          <Route path='/viewed' element={<Viewed />} />
          <Route path='/membership' element={<Membership />} />
          <Route path='/showCard' element={<ShowCard />} />
          <Route path='/otp' element={<Otp />} />

          <Route path='/saved' element={<Saved />} />

          <Route path='/edit' element={<Edit />} />

          <Route path='/terms' element={<Terms />} />

          <Route path='/cancellation' element={<Cancellation />} />
        
          <Route path='/anotherviewed' element={<AnotherViewed />} />
          

          <Route path='/smspage' element={<SMSPage />} />

        </Routes>
        
        {!(window.location.href.indexOf('/freesearch') > -1) ? <Footer /> : null}
      </BrowserRouter>
      
    </div>
  );
}

export default App;
