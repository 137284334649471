import React, { useState, useEffect } from "react";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from "sweetalert";

import {
  addregister,
  getreligionlist,
  getregislist,
  getcastelist,
  getmaritallist,
  login,
  setCurrentUser,
  getcolorlist,
  getstarlist,
  getzodiaclist,
  getdirectionlist,
  getdistrictslist,
} from "./../components/action/user";

import { useNavigate, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextareaAutosize } from "@material-ui/core";
import isEmpty from "./../lib/isEmpty";
import { useDispatch } from "react-redux";
import Otp from "../ui/otp";

import DatePicker from "react-date-picker";

// import isEmpty from "../../lib/isEmpty";

const useStyles = makeStyles((theme) => ({
  InputLabel: {
    color: "rgb(0,0,0)",
    fontFamily: "Source Sans Pro",
  },
}));

toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  religion: "",
  subcaste: "",
  sex: "",
  castename: "",
  maritalname: "",
  date: "",
  month: "",
  year: "",
  age1: "",
  age2: "",
  userid: "",
  city: "",
};

const initialloginFormValue = {
  logindate: "",
  loginmonth: "",
  loginyear: "",
  phonenumber: "",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Tabp() {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  // const historys = useHistory();
  const history = useNavigate();
  // const dispatch = useDispatch();
  // const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loginformValue, setLoginFormValue] = useState(initialloginFormValue);
  const [validateError, setValidateError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [options, setoption] = useState([]);
  const [religions, setreligion] = useState([]);
  const [castes, setcaste] = useState([]);
  const [zodiacs, setzodiac] = useState([]);
  // const [maritals, setmarital] = useState();

  const [districts, setdistricts] = useState([]);

  const [values, onChangess] = useState(new Date());

  const [castenone, setcastenone] = useState(false);

  // const [religions, setreligion] = useState();
  // const [religions, setreligion] = useState();

  const handleFile = (event) => {
    event.preventDefault();
    console.log(event.target.files[0]);
    const { id, files } = event.target;
    //settmpupimagefront(URL.createObjectURL(event.target.files[0]));

    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
    //setValidateError(formData)
  };

  const onChange = (e) => {
    e.preventDefault();
    // var testAbdul = convertThis(event,numchar);
    console.log(e.target);
    const { id, value } = e.target;
    // console.log(value);
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
    //setValidateError(formData)
  };
  const onChangelogin = (e) => {
    e.preventDefault();
    // var testAbdul = convertThis(event,numchar);
    console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...loginformValue, ...{ [id]: value } };
    setLoginFormValue(formData);
    console.log(loginformValue);
    //setValidateError(formData)
  };

  const dayoption = [];
  for (let i = 1; i <= 31; i++) {
    dayoption.push(i);
  }

  const monthoption = [];
  for (let i = 1950; i <= 2030; i++) {
    monthoption.push(i);
  }

  const {
    religion,
    subcaste,
    date,
    month,
    year,
    sex,
    castename,
    maritalname,
    age1,
    age2,
    userid,
    city,
    zodiacname,
    getdhosamlist,
    dhosamname,
    starname,
  } = formValue;

  const {   logindate,
  loginmonth,
  loginyear, phonenumber } = loginformValue;

  // const handleFormSubmit = async (e) => {
  //     //console.log("saran");
  //     e.preventDefault();
  //     console.log(formValue);
  //     let reqData = {
  //         religion,
  //         subcaste,
  //         date,
  //         month,
  //         year,
  //         sex,
  //         castename,
  //         maritalname,
  //         age,
  //     };
  // }

  const sexch = [
    { title: "ஆண்", value: "Male" },
    { title: "பெண்", value: "Female" },
  ];

  const ages1 = [
    { title: "18", value: "18" },
    { title: "19", value: "19" },
    { title: "20", value: "20" },
    { title: "21", value: "21" },
    { title: "22", value: "22" },
    { title: "23", value: "23" },
    { title: "24", value: "24" },
    { title: "25", value: "25" },
    { title: "26", value: "26" },
    { title: "27", value: "27" },
    { title: "28", value: "28" },
    { title: "29", value: "29" },
    { title: "30", value: "30" },
    { title: "31", value: "31" },
    { title: "32", value: "32" },
    { title: "33", value: "33" },
    { title: "34", value: "34" },
    { title: "35", value: "35" },
    { title: "36", value: "36" },
    { title: "37", value: "37" },
    { title: "38", value: "38" },
    { title: "39", value: "39" },
    { title: "40", value: "40" },
    { title: "41", value: "41" },
    { title: "42", value: "42" },
    { title: "43", value: "43" },
    { title: "44", value: "44" },
    { title: "45", value: "45" },
    { title: "46", value: "46" },
    { title: "47", value: "47" },
    { title: "48", value: "48" },
    { title: "49", value: "49" },
    { title: "50", value: "50" },
  ];

  const ages2 = [
    { title: "19", value: "19" },
    { title: "20", value: "20" },
    { title: "21", value: "21" },
    { title: "22", value: "22" },
    { title: "23", value: "23" },
    { title: "24", value: "24" },
    { title: "25", value: "25" },
    { title: "26", value: "26" },
    { title: "27", value: "27" },
    { title: "28", value: "28" },
    { title: "29", value: "29" },
    { title: "30", value: "30" },
    { title: "31", value: "31" },
    { title: "32", value: "32" },
    { title: "33", value: "33" },
    { title: "34", value: "34" },
    { title: "35", value: "35" },
    { title: "36", value: "36" },
    { title: "37", value: "37" },
    { title: "38", value: "38" },
    { title: "39", value: "39" },
    { title: "40", value: "40" },
    { title: "41", value: "41" },
    { title: "42", value: "42" },
    { title: "43", value: "43" },
    { title: "44", value: "44" },
    { title: "45", value: "45" },
    { title: "46", value: "46" },
    { title: "47", value: "47" },
    { title: "48", value: "48" },
    { title: "49", value: "49" },
    { title: "50", value: "50" },
    { title: "51", value: "51" },
  ];

  const maritals = [
    { title: "முதல் மணம்", value: "Marriage" },
    { title: "மறுமணம்", value: "Remarriage" },
  ];
  const [stars, setstar] = useState([]);

  const [dhosams, setdhosam] = useState([]);

  const getUserData = async () => {
    // console.log(userId);
    var test = await getreligionlist();
    // console.log("fdssfdsfdsf", test);
    const option1 = await test.userValue.map((d) => ({
      title: d.religion,
      value: d._id,
    }));
    setreligion(option1);

    // var test = await getmaritallist();
    // // console.log("fdssfdsfdsf", test);
    // const maritals = await test.userValue.map(d => ({
    //     "title": d.maritalname,
    //     "value": d._id
    // }));
    // setmarital(maritals);
    var test = await getdistrictslist();
    const districts = await test.userValue.map((d) => ({
      title: d.city,
      value: d._id,
    }));
    setdistricts(districts);
    var test = await getcastelist();
    // console.log("fdssfdsfdsf", test);
    const castes = await test.userValue.map((d) => ({
      title: d.castename,
      value: d._id,
    }));
    setcaste(castes);
    var test = await getstarlist();
    //
    // console.log("fdssfdsfdsf", test);
    const stars = await test.userValue.map((d) => ({
      title: d.starname,
      value: d._id,
    }));
    setstar(stars);

    var test = await getzodiaclist();
    //
    // console.log("fdssfdsfdsf", test);
    const zodiacs = await test.userValue.map((d) => ({
      title: d.zodiacname,
      value: d._id,
    }));
    setzodiac(zodiacs);

    var test = await getdhosamlist();

    // console.log("fdssfdsfdsf", test);
    const dhosamlist = await test.userValue.map((d) => ({
      title: d.dhosamname,
      value: d._id,
    }));
    setdhosam(dhosamlist);
  };

  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();
    console.log(formValue);
    let reqData = {
      userid,
      religion,
      subcaste,
      date,
      month,
      year,
      sex,
      castename,
      maritalname,
      age1,
      age2,
      city,
    };
    console.log("req", reqData);
    var filterarray =
      "userid=" +
      userid +
      "&religion=" +
      religion +
      "&subcaste=" +
      subcaste +
      "&castename=" +
      castename +
      "&sex=" +
      sex +
      "&maritalname=" +
      maritalname +
      "&age1=" +
      age1 +
      "&age2=" +
      age2 +
      "&city=" +
      city;
    window.location = "/freesearch?" + filterarray;
  };

  const handleFormSubmitforlogin = async (e) => {
    //console.log("saran");
    e.preventDefault();
    console.log(formValue);
    let reqData = {
        dob:loginyear+"-"+loginmonth+"-"+logindate,
      date: logindate,
      month: loginmonth,
      year: loginyear,
      phone_number: phonenumber,
    };
    console.log("req", reqData);
    let { error, result } = await login(reqData);
    if (isEmpty(error)) {
      setLoginFormValue(loginformValue);
      // await dispatch(setCurrentUser(result));
      window.location = "/";
    }
    console.log(error);
    if(error.dob){
      swal("உங்கள் கணக்கு பதிவு செய்யவில்லை")
    }
    if(error&&!error.dob){
      swal("நீங்கள் பதிவு செய்த விவரம் ஒப்புகொள்ளவில்லை.மேலும் தகவல் அறிய அழைக்கவும்-9365232563")
    }
    if (isEmpty(error)) {
      setLoginFormValue(loginformValue);
      // await dispatch(setCurrentUser(result));
      window.location = "/";
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    //logout(history)
    getUserData();
  }, []);

  return (
    <>
      <div className="colorzz">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="tabss">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tabssss"
          >
            <Tab
              style={{ fontSize: "15px", fontWeight: "700" }}
              label="MEMBER LOGIN"
              {...a11yProps(0)}
              className="tabsss"
            />
            {!localStorage.user_token ? (
              <Tab
                style={{ fontSize: "15px", fontWeight: "700" }}
                label="FIND MY PARTNER"
                {...a11yProps(1)}
                className="tabsss"
              />
            ) : null}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleFormSubmitforlogin}
          >
            <div className="form-group">
              <div className="form-group">
                <label style={{ fontSize: "16px",display:"block" }}>பிறந்த தேதி</label>
                {/* <input
                  type="date"
                  autoComplete="off"
                  id="dateofbirth"
                  dateFormat="dd/MM/yyyy"
                  style={{ padding: "20px", fontSize: "16px" }}
                  onChange={onChangelogin}
                  value={dateofbirth}
                  className="form-control"
                  name="data[Register][date_of_birth]"
                  onkeypress="javascript:convertThis(event);"
                /> */}
                <select
                  labelId="demo-simple-select-label"
                  id="logindate"
                  value={logindate}
                    onChange={onChangelogin}
                    className="daaa"
    //               style={{
    //                 padding: "5px 10px",
    // fontSize: "16px",
    // margin: "0 5px"
    //               }}
                >
                  <option value="">Date</option>
                  {dayoption.map((date) => (
                    <option value={date}>{date}</option>
                  ))}
                </select>
                <select
                  labelId="demo-simple-select-label"
                  id="loginmonth"
                  value={loginmonth}
                    onChange={onChangelogin}
                    className="daaa"
    //               style={{
    //                 padding: "5px 10px",
    // fontSize: "16px",
    // margin: "0 5px"
    //               }}
                >
                  <option value="">Month</option>
                  <option value={1}>January</option>
                  <option value={2}>February</option>
                  <option value={3}>March</option>
                  <option value={4}>April</option>
                  <option value={5}>May</option>
                  <option value={6}>June</option>
                  <option value={7}>July</option>
                  <option value={8}>August</option>
                  <option value={9}>September</option>
                  <option value={10}>October</option>
                  <option value={11}>November</option>
                  <option value={12}>December</option>
                </select>
                <select
                  labelId="demo-simple-select-label"
                  id="loginyear"
                  value={loginyear}
                    onChange={onChangelogin}
                    className="daaa"
    //               style={{
    //                 padding: "5px 10px",
    // fontSize: "16px",
    // margin: "0 5px"
    //               }}
                >
                  <option value="">Year</option>
                  {monthoption.map(year => (
                    <option value={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div>
                        <label style={{ fontSize: "16px" }}>பிறந்த தேதி</label><br></br>
                        <DatePicker className='dateeee' format="dd/MM/yyyy" onChange={(value) => setLoginFormValue({ ...loginformValue, ...{ ["dateofbirth"]: value } })} value={dateofbirth} />
                        <p className="dumm">DD/MM/YYYY</p>
                    </div>  */}

            <div className="form-group">
              <label style={{ fontSize: "16px" }}>
                மொபைல் நம்பர் <span className="reguired">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                onChange={onChangelogin}
                id="phonenumber"
                style={{ padding: "20px", fontSize: "16px" }}
                value={phonenumber}
                name="data[Register][phonenumber]"
                onkeypress="javascript:convertThis(event);"
              />
                {validateError.dob && (
                                                <span className={classes.textDanger}>
                                                    {validateError.dob}
                                                </span>
                                            )}
              {/*<small>OTP will be send to this mobile number</small>*/}
              {/*<small style="float:right"><input type="button" id="resend_otp" value="Resend OTP ?"></small>*/}
            </div>
            {/*<div class="form-group otp">*/}
            {/*    <label>OTP</label>*/}
            {/*    <input type="text" id="otp" class="form-control" name="data[Register][otp]"/>*/}
            {/*</div>*/}
            <input
              type="submit"
              className="btn send-otp"
              defaultValue="SUBMIT"
              style={{
                backgroundColor: "#662e91",
                color: "white",
                fontSize: "16px",
                fontWeight: "bold",
               
                borderColor: "#662e91",
              }}
            />
          </form>
          <div className="advanced-searc-lnk">
            <a style={{ fontSize: "15px" }} href="/registration">
              நீங்கள் புதியவரா? உங்கள் ஜாதகத்தை பதிவு செய்ய இங்கே கிளிக்
              செய்யவும்.
            </a>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                    <label style={{ fontSize: "21px", fontWeight: "800" }}>
                    SM-ID
                  </label>
                  <input
                    type="text"
                    style={{ fontSize: "18px", height:"38px" }}
                      className="form-control"
                      placeholder="Eg : 12345"
                    onChange={onChange}
                    id="userid"
                    value={userid}
                    name="userid"
                    onkeypress="javascript:convertThis(event);"
                  />
                  {/*<input type="text" class="form-control" name="id" onkeypress="javascript:convertThis(event);">*/}
                </div>
                <div className="col-md-6 col-sm-6">
                  <label
                    style={{
                      paddingBottom: "8px",
                      fontSize: "16px",
                      fontWeight: "800",
                    }}
                  >
                    தேடுவது{" "}
                  </label>
                  <select
                    class="form-select drs form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={sex}
                    id="sex"
                    onChange={onChange}
                  >
                    <option value="">Select Gender</option>
                    {sexch.length
                      ? sexch.map((e) => (
                          <option value={e.value}>{e.title}</option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <label
                    style={{
                      paddingBottom: "8px",
                        fontSize: "16px",
                      fontWeight: "800",
                    }}
                  >
                    மதம்
                  </label>
                  <select
                    class="form-select drs form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={religion}
                    id="religion"
                    onChange={onChange}
                  >
                    <option value="">Select Religion</option>
                    {religions.length
                      ? religions.map((e) => (
                          <option value={e.value}>{e.title}</option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="col-md-6 col-sm-6">
                  <label
                    style={{
                      paddingBottom: "8px",
                        fontSize: "16px",
                      fontWeight: "800",
                    }}
                  >
                    ஜாதி
                  </label>
                  <select
                    class="form-select drs form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={castename}
                    id="castename"
                    onChange={onChange}
                  >
                    <option value="">Select Caste</option>
                    {castes.length
                      ? castes.map((e) => (
                          <option value={e.value}>{e.title}</option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="form_group">
              <div className="row">
                {/* <div className="col-md-6 col-sm-6">
                                <label style={{ fontSize: "15px", fontWeight: "800" }}>உட்பிரிவு</label>
                                <input type="text" className="form-control" onChange={onChange} id="subcaste"  value={subcaste} name="data[Register][subcaste]" onkeypress="javascript:convertThis(event);" />
                            </div> */}
                <div className="col-md-6 col-sm-6">
                  <label
                    style={{
                      paddingBottom: "8px",
                        fontSize: "16px",
                      fontWeight: "800",
                    }}
                  >
                    திருமண நிலை
                  </label>
                  <select
                    class="form-select drs form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={maritalname}
                    id="maritalname"
                    onChange={onChange}
                  >
                    <option value="">திருமண நிலை</option>
                    {maritals.length
                      ? maritals.map((e) => (
                          <option value={e.value}>{e.title}</option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="col-md-6 col-sm-6">
                  <label
                    style={{
                      paddingBottom: "8px",
                        fontSize: "16px",
                      fontWeight: "800",
                    }}
                  >
                    மாவட்டம்
                  </label>
                  <select
                    class="form-select drs form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={city}
                    id="city"
                    onChange={onChange}
                  >
                    <option value="">Select City</option>
                    {districts.length
                      ? districts.map((e) => (
                          <option value={e.value}>{e.title}</option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label
                className="age-top"
                style={{
                  paddingBottom: "8px",
                  fontSize: "16px",
                  fontWeight: "800",
                }}
              >
                வயது{" "}
              </label>
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <select
                    class="form-select drs form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={age1}
                    id="age1"
                    onChange={onChange}
                  >
                    <option value="">Select From Age</option>
                    {ages1.length
                      ? ages1.map((e) => (
                          <option value={e.value}>{e.title}</option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="col-md-6 col-sm-6">
                  <select
                    class="form-select drs form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={age2}
                    id="age2"
                    onChange={onChange}
                  >
                    <option value="">Select To Age</option>
                    {ages2.length
                      ? ages2.map((e) => (
                          <option value={e.value}>{e.title}</option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div className="form_group">
                <div className="row">
                  {/* <div className="col-md-6 col-sm-6">
                                <label style={{ fontSize: "15px", fontWeight: "800" }}>உட்பிரிவு</label>
                                <input type="text" className="form-control" onChange={onChange} id="subcaste"  value={subcaste} name="data[Register][subcaste]" onkeypress="javascript:convertThis(event);" />
                            </div> */}
                  <div className="col-md-6 col-sm-6">
                    <label
                      style={{
                        paddingBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "800",
                      }}
                    >
                      நட்சத்திரம்
                    </label>
                    <select
                      class="form-select drs form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      value={starname}
                      id="starname"
                      onChange={onChange}
                    >
                      <option value="">நட்சத்திரம்</option>
                      {stars.length
                        ? stars.map((e) => (
                            <option value={e.value}>{e.title}</option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <label
                      style={{
                        paddingBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "800",
                      }}
                    >
                      ராசி
                    </label>
                    <select
                      class="form-select drs form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      value={zodiacname}
                      id="zodiacname"
                      onChange={onChange}
                    >
                      <option value="">Select Zodia</option>
                      {zodiacs.length
                        ? zodiacs.map((e) => (
                            <option value={e.value}>{e.title}</option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_group">
                <div className="row">
                  {/* <div className="col-md-6 col-sm-6">
                                <label style={{ fontSize: "15px", fontWeight: "800" }}>உட்பிரிவு</label>
                                <input type="text" className="form-control" onChange={onChange} id="subcaste"  value={subcaste} name="data[Register][subcaste]" onkeypress="javascript:convertThis(event);" />
                            </div> */}
                  <div className="col-md-6 col-sm-6">
                    <label
                      style={{
                        paddingBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "800",
                      }}
                    >
                      தோஷம்
                    </label>
                    <select
                      class="form-select drs form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      value={dhosamname}
                      id="dhosamname"
                      onChange={onChange}
                    >
                      <option value="">தோஷம்</option>
                      {dhosams.length
                        ? dhosams.map((e) => (
                            <option value={e.value}>{e.title}</option>
                          ))
                        : null}
                    </select>
                  </div>
                  {/* <div className="col-md-6 col-sm-6">
                    <label
                      style={{
                        paddingBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "800",
                      }}
                    >
                      ராசி
                    </label>
                    <select
                      class="form-select drs form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      value={city}
                      id="city"
                      onChange={onChange}
                    >
                      <option value="">Select Zodia</option>
                      {zodiacs.length
                        ? zodiacs.map((e) => (
                            <option value={e.value}>{e.title}</option>
                          ))
                        : null}
                    </select>
                  </div> */}
                </div>
              </div>

              <div className="form-group row alignzzz" style={{ alignItems: "center" }}>
                  <div className="col-md-8 col-sm-6">
                  <label style={{ fontSize:"15px" }}> ஜாதி தடை இல்லை </label>
                  <FormControlLabel
                      style={{ color: "black", fontSize: "20px" }}
                      className="gggg"
                    control={<Checkbox onChange={(event) => setcastenone(!castenone)} name="castenone" />}

                  />
                  </div>
                  
                  <div className="col-md-4 col-sm-6">
                    <input
                      type="submit"
                      name="search"
                      className="button small btn btn-primary"
                      style={{ fontSize: "16px", fontWeight: "bold", backgroundColor: "#662e91", borderColor: "#662e91", width:"100%", borderRadius:"4px" }}
                      defaultValue="தேடல்"
                    />
                  </div>
                {/* <div className="col-md-4">
                  <FormControlLabel
                    style={{ color: "black", fontSize: "20px" }}
                    control={<Checkbox onChange={(event) => setcastenone(!castenone)} name="castenone" />}

                  />
                </div> */}
              </div>

            </div>

              {/* <div>
              <input
                type="submit"
                name="search"
                className="button small btn btn-primary"
                style={{ fontSize: "16px", fontWeight: "bold", backgroundColor: "#ea8f08", borderColor: "#ea8f08" }}
                defaultValue="தேடல்"
              />
            </div> */}
          </form>
          <div className="advanced-searc-lnk">
              <a style={{ fontSize: "15px" }} href="/registration">
              நீங்கள் புதியவரா? உங்கள் ஜாதகத்தை பதிவு செய்ய இங்கே கிளிக்
              செய்யவும்.
            </a>
          </div>
        </TabPanel>

        {/* <TabPanel value={value} index={1}>

                <form className={classes.form}
                    noValidate
                    onSubmit={handleFormSubmitforlogin}>
                    <div className="form-group">
                        <div className="form-group">
                            <label style={{ fontSize: "16px" }}>பிறந்த தேதி</label>
                            <input type="date" autoComplete="off" id="dateofbirth" style={{ padding: "20px", fontSize: "16px" }} onChange={onChangelogin} value={dateofbirth} className="form-control" name="data[Register][date_of_birth]" onkeypress="javascript:convertThis(event);" />
                        </div>
                       
                    </div>
                    <div className="form-group">
                        <label style={{ fontSize: "16px" }}>மொபைல் நம்பர் <span className="reguired">*</span></label>
                        <input type="text" className="form-control" onChange={onChangelogin} id="phonenumber" style={{ padding: "20px", fontSize: "16px" }} value={phonenumber} name="data[Register][phonenumber]" onkeypress="javascript:convertThis(event);" />
                      
                    </div>
                  
                    <input type="submit" className="btn send-otp" defaultValue="SUBMIT" style={{ backgroundColor: "#b50ab5", color: "white", fontSize: "16px", fontWeight: "bold" }} />
                </form>
                <div className="advanced-searc-lnk">
                    <a style={{ fontSize: "15px" }} href="/registration">நீங்கள் புதியவரா? உங்கள் ஜாதகத்தை பதிவு செய்ய இங்கே கிளிக் செய்யவும்.</a>
                </div>
          </TabPanel> */}
        </Box>
      </div>

    </>
  );
}
