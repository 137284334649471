
import React, { useEffect, useState } from 'react';
import { getregister, getregisterfilter, getcaste, getbank } from "../action/user";
import { useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Circles } from 'react-loader-spinner'
import FavoriteIcon from '@mui/icons-material/Favorite';
import './freesearch.css';
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery } from "react-query";
import config from "../../lib/config";
import './freesearch.css'
import AOS from 'aos'
import Pagination from '@material-ui/lab/Pagination';
function SMSPage(props) {

    const [registerfilter, setRegisterfilters] = useState([]);
    const [malecount, setMalecount] = useState(0);
    const [femalecount, setFemalecount] = useState(0);
    const [totalProfileCount, setTotalProfileCount] = useState(1);
    const [sexparam, setsexparam] = useState("");
    const [searchsex, setsearchsex] = useState("");
    const search = useLocation().search;
    const [searchparamsf, setsearchparamsf] = useState(search);
    const [Loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [banks, setBanks] = useState([]);
    // var filterarray="religion="+religion+"&subcaste="+subcaste+"&castename="+castename+"&sex="+sex+"&maritalname="+maritalname;


    // const subcaste = new URLSearchParams(search).get('subcaste');
    // const sex = new URLSearchParams(search).get('sex');
    // const maritalname = new URLSearchParams(search).get('maritalname');
    const [castes, setCastes] = useState([]);
    // const caste = new URLSearchParams(search).get('castename');
    // const gender = new URLSearchParams(search).get('sex');

    const location = useLocation();




    const fetchPosts = async ({ pageParam = 0 }) => {

        setLoading(true)
        // var filterobj={page:page-1};
        // var filterobj={};
        var filterobj = { page: pageParam };


        let encodedString = location.search;
        let decodedString = decodeURIComponent(encodedString);
        console.log(decodedString);


        // Accessing the pathname and search from location object


        let searchParams = new URLSearchParams(decodedString.split('?')[1]);

        // // Getting the value of 'sex' parameter
        // let sex = searchParams.get('sex');

        // // Getting the value of 'castename' parameter
        // let castename = searchParams.get('castename');


        const castename = searchParams.get('castename');
        if (castename) {
            filterobj.castename = castename;
        }
        const sexsearch = searchParams.get('sex');
        if (sexsearch) {
            if (sexsearch == "Female" || sexsearch == "female") {
                filterobj.sex = "Male";

            }
            else if (sexsearch == "male" || sexsearch == "Male") {
                filterobj.sex = "Female";



            }

            setsearchsex(sexsearch)
        }
        console.log("casteeee", castename)
        console.log("genderrrrr", sexsearch)


        var test = await getregisterfilter(filterobj);

        console.log("testttttttt", test);

        //   setRegisterfilters(test.userValue);
        //     const response = await fetch(
        //       `https://picsum.photos/v2/list?page=${pageParam}&limit=10`
        //     );
        // const results = await response.json();
        setMalecount(test.Malecount)
        setFemalecount(test.Femaleount)
        setTotalProfileCount(test.totalCount)
        setLoading(false)
        return { results: test.userValue, nextPage: pageParam + 1, totalPages: test.totalCount / 20 || 0 };
    };
    const {
        data,
        isLoading,
        isError,
        hasNextPage,
        refetch,
        fetchNextPage
    } = useInfiniteQuery("posts", fetchPosts, {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.nextPage < lastPage.totalPages) return lastPage.nextPage;
            return undefined;
        }
    });

    useEffect(() => {
        const sex = new URLSearchParams(search).get('sex');
        if (sex) {
            setsexparam(sex);
        }

        banklist()
    }, [])


    const handleChange = (event, value) => {
        console.log(value, "hariiiiiiiiiiiiiiiiiiii")
        setPage(value);
    };



    const banklist = async () => {
        var test = await getbank();
        setBanks(test.userValue);
    }


    const refresh = async () => {
        await refetch();
    }

    useEffect(() => {
        //logout(history)
        refresh();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
    }, [sexparam, page]);
    const initialFormValue = {
        sex: "",
    };
    const [formValue, setFormValue] = useState(initialFormValue);
    const [width, setWidth] = useState(window.innerWidth);
    const [height1, setHeight] = useState(window.innerHeight);
    const sexch = [
        { title: "ஆண்", value: "Male" },
        { title: "பெண்", value: "Female" },
    ];


    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {


        // console.log(width);

        // console.log(height1);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    });


    return (
        <>
            <div className="free-b" >
                <div className="container">
                    <div className="text-center" style={{ marginTop: '20px', marginBottom: '80px' }}>
                        <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>
                            <div className="col-md-12 prink" style={{ marginTop: '35px' }}>
                                <div className="row">
                                    {/* <div className="col-md-8">
                                        {searchsex ? <div className='hari' style={{ float: 'left', fontSize: "20px", fontWeight: "bolder" }}>{searchsex == "Male" ? <button type="button" onClick={() => setsexparam("Male")} class="btn btn-sa"> ஆண் ({malecount})</button> : <button type="button" onClick={() => setsexparam("Female")} class="btn btn-sa">பெண் ({femalecount})</button>}</div>
                                            : <div className='hari' style={{ float: 'left', fontSize: "20px", fontWeight: "bolder" }}><button type="button" onClick={() => setsexparam("Male")} class="btn btn-sa"> ஆண் ({malecount})</button> <button type="button" onClick={() => setsexparam("Female")} class="btn btn-sa">பெண் ({femalecount})</button></div>
                                        }
                                    </div> */}


                                    {/* <div className="col-md-4">
                     <label style={{ paddingBottom:"8px", fontSize:"18px", fontWeight:"800"}}>தேடுவது </label>
                                        <Autocomplete
                                                options={sexch}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    setsexparam(newValue.value);

                                                }}
                                                type="text"
                                                name="sex"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label=""
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                    </div>  */}
                                </div>
                            </div>
                            {Loading && <div style={{ TextAlign: "center" }}>
                                <Circles className="satta" color="red" height={50} width={50} />
                            </div>}
                            {isLoading ? (
                                <p></p>
                            ) : isError ? (
                                <p>There was an error</p>
                            ) : (
                                // <InfiniteScroll hasMore={hasNextPage} loadMore={fetchNextPage}>
                                <div>
                                    {data.pages.map(page => (
                                        (page.results.length < 5 ? (page.results) : (page.results).slice(0, 4)).map((post) => (
                                            <div className="col-md-12 search-result" style={{ marginTop: '35px' }}>
                                                <div className="title row">
                                                    <div className="col-md-6" style={{ paddingLeft: 'unset', fontSize: '15px' }}>
                                                        <p className='name' style={{ fontSize: "16px" }}>{post.userid} <span style={{ fontSize: "18px" }}> - {post.mname}</span></p>
                                                    </div>
                                                    <div className="col-md-6" style={{ paddingRight: 'unset', fontSize: '15px' }}>
                                                        <a href={"/batman/" + post._id} className='buttonsss'>View Profile</a>
                                                    </div>

                                                </div>
                                                <hr style={{ marginTop: '10px' }} />
                                                <div className="row " >
                                                    <div className="col-md-2" >
                                                        <a href={"/batman/" + post._id}>
                                                            <img src={post.photopath.length && post.photopath[0] ? config.API + "/images/user/" + post.photopath : post.sex == "Male" ? require('../../assests/images/man.png') : require('../../assests/images/woman.png')} alt="Profile" className="img-responsive" style={{ height: "200px" }} /></a>
                                                    </div>
                                                    <div className="col-md-5 content">

                                                        <table className='Droid Sans' style={{ width: '100%', fontSize: '15px', fontFamily: 'fantasy' }}>

                                                            <tbody>

                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>பாலினம் </b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}> {post.sex == 'Male' ? "ஆண்" : "பெண்"}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>வயது</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}>{post.age = new Date().getFullYear() - post.year + "" + " Yrs"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>பிறந்த தேதி</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}> {post.dob = post.date + "-" + post.month + "-" + post.year}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>தந்தை பெயர்</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', marginLeft: '10px', paddingBottom: "10px", color: "#ff4802" }}>{post.father_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>தாய் பெயர்</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}>{post.mother_name}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>ஜாதி</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}>{post.castename}</td>
                                                                </tr>
                                                                {/* <tr>
                                            <td style={{ color: '#000', textAlign: 'left', paddingBottom:"10px" }}><b>நட்சத்திரம் </b></td>
                                            <td style={{ width: '22px' , paddingBottom:"10px"}}>:</td>
                                            <td style={{ textAlign: 'left', paddingBottom:"10px" }}> {post.starname}</td>
                                        </tr> */}
                                                            </tbody></table>

                                                    </div>
                                                    <div className="col-md-5 content">
                                                        <table className='Droid' style={{ width: '100%', fontSize: '15px' }}>
                                                            <tbody><tr>
                                                                <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>மதம்</b></td>
                                                                <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}>{post.religion}</td>
                                                            </tr>
                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>கல்வி</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}>{post.educ}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>தொழில்</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}>{post.occup}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>உயரம்</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}>{post.height}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>மாவட்டம்</b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}> {post.city} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ color: '#000', textAlign: 'left', paddingBottom: "10px" }}><b>உட்பிரிவு </b></td>
                                                                    <td style={{ width: '22px', paddingBottom: "10px" }}>:</td>
                                                                    <td style={{ textAlign: 'left', paddingBottom: "10px", color: "#ff4802" }}> {post.subcaste}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>

                                        ))))}
                                </div>
                                // </InfiniteScroll>
                            )}









                            {!totalProfileCount && <div class="empty-state">
                                {/* <div class="empty-state__content">
                                    <div class="empty-state__icon">
                                        <img src={require('../../assests/img/norec.png')} alt="" />
                                    </div>
                                    <div class="empty-state__message">No Matching Profiles.</div>
                                </div> */}
                            </div>}
                            {/* <div className="row">
                                <div className="col-12" style={{ TextAlign: "center" }}>
                                    <Pagination count={Math.round((totalProfileCount / 20))} page={page} color="primary" onChange={handleChange} />
                                </div>
                            </div> */}




                            <section className="bankdetail mtp" id="bankdetail">
                                <div className="container">
                                    <div className="bank">
                                        <div>
                                            <h3 className="payonline">PAY ONLINE</h3>
                                            <div className="row">
                                                {/* <div className="col-md-6">
                  
                    <img className="logo" src={require('../../assests/images/gp (1).png')} />
                    <p className="pno">Phone Number :  99 4331 5331</p>
                    <div>
                      <h1>QR Code</h1>
                      <img className="logo qrscan" src={require('../../assests/images/qr (1).jpeg')} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img className="logo" src={require('../../assests/images/phpay (2).png')} />
                    <p className="pno">Phone Number :  99 4331 5331</p>
                    <div>
                      <h1>QR Code</h1>
                      <img className="logo qrscan" src={require('../../assests/images/qr (1).jpeg')} />
                    </div>
                  </div> */}
                                                <div className="col-md-6">
                                                    <div className="yeah">
                                                        <img className="logo" src={require('../../assests/images/gp (1).png')} />
                                                        <img className="logo" src={require('../../assests/images/phpay (2).png')} />
                                                        <p className="pno">Phone Number :  99 4331 5331</p>
                                                        <p className="pno">Phone Number :  98 4213 4672</p>
                                                        <p className="pno">Phone Number :  82 2020 4747</p>

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <h1>QR Code</h1>
                                                        <img className="logo qrscan" src={require('../../assests/images/qr_pic.jpeg')} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <div className="row">              
                <h3 className="payonline">PAY ONLINE
                </h3>
              
                <div className="img-pay" ><span ><img className="img-go" src={require('../../assests/images/Goog-Pay.jpg')} style={{ height: '25px' }} /></span><span className="wwww">  Google Pay : {banks.length&&banks[0].bankpno}</span></div>
                <div className="img-pay"><span className="img-come"><img src={require('../../assests/images/phonepe-logo.png')} style={{ height: '25px' }} /></span><span className="wwee">  Phone Pay : {banks.length&&banks[0].bankpno}</span></div>
              </div> */}
                                        <div className="col-md-12  bankdetails">
                                            <h1>நீங்கள் நேரடியாக பணத்தை வங்கியிலும் செலுத்தலாம்.</h1>
                                            <h3>Bank Account Details</h3>
                                            <div className="row">

                                                {banks.length && banks.map(el => <div className=" col-md-4 col-sm-10 mx-auto">
                                                    <div className="item">
                                                        <div className="card">
                                                            {/* <img src={ require('../assests/images/canarabank.jpg') } alt="" />  */}

                                                            <div className="content match-height">
                                                                <p><b >BANK</b><span className="dot">:</span><span className="a1a1">{el.bankname}</span></p>
                                                                <p><b >A/C NAME </b><span className="dot">:</span><span className="a1a1"> {el.accountname}</span></p>
                                                                <p><b>A/C NO </b><span className="dot">:</span><span className="a1a1"> {el.accountno}</span></p>
                                                                <p><b>BRANCH</b><span className="dot">:</span><span className="a1a1" style={{ fontSize: "14px" }}> {el.branchname}</span></p>
                                                                {/*<p><b>BRANCH CODE </b><span class="dot">:</span><span>253</span></p> */}
                                                                <p><b>IFSC CODE</b><span className="dot">:</span><span className="a1a1"> {el.ifsc_no}</span></p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>)}


                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </section >



                        </div></div>
                </div>
            </div>

        </>
    )
}

export default SMSPage;
