let key = {};
// if (process.env.NODE_ENV === "production") {
  console.log("Set Production Config");
  const API_URL = "http://srimalligaimatrimony.com";

  key = {
    secretOrKey: "",
    Recaptchakey: "", //local

    API: `${API_URL}:3000`,
  };
// } else {
//   console.log("Set Development Config");
//   const API_URL = "http://164.90.181.133";

//   key = {
//     secretOrKey: "",
//     Recaptchakey: "", //local

//     API: `${API_URL}:3000`,
//   };
// }

export default key;


// let key = {};

// if (process.env.NODE_ENV === "production") {
//   key = {
//     secretOrKey: "test",
//     mongoURI: "",
//     port: 2053,
//     siteUrl: "",

//     // Email Gateway
//     emailGateway: {
//       fromMail: "",
//       nodemailer: {
//         host: "smtp.gmail.com",
//         port: 587,
//         secure: false, // true for 465, false for other ports
//         auth: {
//           user: "", // generated ethereal user
//           pass: "", // generated ethereal password
//         },
//       },
//     },
//   };
// } else {
//   console.log("Set Development Config");
//   key = {
//     secretOrKey: "test",
//     mongoURI: "mongodb://localhost:27017/thirumagal",
//     port: 3001,
//     siteUrl: "http://localhost:3000",
//     // Email Gateway
//     emailGateway: {
//       fromMail: "sakuratrading@stagingfeathers.com",
//       nodemailer: {
//         host: "stagingfeathers.com",
//         port: 465,
//         secure: false, // true for 465, false for other ports
//         auth: {
//           user: "sakuratrading@stagingfeathers.com", // generated ethereal user
//           pass: "spxgdw2c2kz6", // generated ethereal password
//         },
//       },
//     },
//   };
// }

// export default key;
