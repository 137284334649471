import React, { useState, useEffect } from "react";
import { getterms } from '../action/user';

function Cancellation() {

    const [privacy_title, settitle] = useState()
    const [privacy_content, setcontent] = useState()
    const gettermsData = async () => {
        var test = await getterms();
        settitle(test.userValue.title);
        setcontent(test.userValue.content);
    };

    useEffect(() => {
        //logout(history)
        gettermsData();
    }, []);

    return (
        <div className="container static-page">
            <div className="content">
                <h2>Cancellation and Refund Policy</h2>
                <p /><div className="cd-pricing-container padding-lr-zero xxl-16 xl-16 l-16 m-16 s-16 xs-16">
                    {/* <div
                        dangerouslySetInnerHTML={{ __html: privacy_content }}
                    /> */}
                    <h3 style={{fontSize:"25px", color: "red"}}>Cancellation Policy</h3>
                    
                    <p> Matrimony Cancellation Policy

                        You may terminate your nest matrimony membership at any time, for any reason. But the payments made by member by way of membership  is treated as non-refundable.</p>
                    <br></br>
                    <h3 style={{fontSize:"25px", color: "red"}}>Refund Policy</h3>
                    
                    <p> Matrimony Refund Policy

                        In the event you terminate your membership, you will not be entitled to a refund of any Membership fees, if any, paid by you.</p>

                    <ul className="cd-pricing-list cd-bounce-invert xxl-16 xl-16 l-16 m-16 s-16 xl-16">
                    </ul>
                </div>
                <p>&nbsp;</p>
                <p />
            </div>
        </div>

    );
}
export default Cancellation;