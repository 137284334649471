import React, { useState, useEffect } from "react";
import { getmember } from '../action/user';
import { getpackage } from "./../action/user";
//import { getpayment } from '../action/user';
import config from "../../lib/config";
import AOS from 'aos';

function Member() {
    const [packages, setPackages] = useState([])
    const getPackData = async () => {
        var test = await getpackage();
        setPackages(test.userValue);
        // console.log(test, "twinkle");
    }

    useEffect(() => {
        //logout(history)
        
        getPackData();
        
    }, []);

    // const [payment_image , setimages] = useState()
    // const [payment_content, setcontents] = useState()

    const [member_image, setimage] = useState()
    const [member_content, setcontent] = useState()
    const getmemberData = async () => {
        var test = await getmember();
        // console.log("image",test);

        // let images = config.API+"/images/user/" + test.userValue.image;
        //  setimages(image);


        //  setcontents(test.userValue.content);



        let image = config.API + "/images/user/" + test.userValue.image;
        setimage(image);


        setcontent(test.userValue.content);
    };

    useEffect(() => {
        //logout(history)
        getmemberData();
    }, []);
    return (

        <div>
            <section className="inner-page-banners">
                <img className="images img-responsive" src={require('../../assests/images/87295banner.png')} />
                <div className="clearfix" />
            </section>

            {/*about heading*/}
            <section style={{ boxShadow: '0px 3px 23px 0px rgb(244, 246, 249)' }}>
                <div className="container about-us">
                    <div className="text-center col-md-offset-4 col-md-3">
                        <div id="triangle_left" />
                        <p>Registration fee</p>
                        <div id="triangle_right" />
                    </div>
                </div>
            </section>
            {/*//about heading*/}
            {/*details*/}

            <section className="ourpackages" style={{ background: '#fafbfd', paddingTop: '30px',  }}>
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2 className="text-center">OUR PACKAGES</h2>
                        </div>
                    </div>
                    <div className="selectpackage" id="selectpackage">
                        <div className="row">
                            {packages.map(post => (
                                <div className="col-md-3 col-sm-6">
                                    <div className="package-boxshadow">
                                        <div className="silver">
                                            <h2>{post.packagename}</h2>
                                            <div className="pricecircle">
                                                <h4><img style={{ height: '20px' }} src={require('../../assests/images/rupee.png')} /></h4>
                                                <h1 style={{ fontSize: '35px', fontWeight: '800' }}>{post.amount}</h1>
                                                <p>ONLY</p>
                                            </div>
                                            <h5>Number of Profiles</h5>
                                            <h3>{post.count}</h3>
                                            <h4 style={{ marginBottom: '20px', fontWeight: "700", lineHeight: "23px", color: "#eb3800" }}>{"Valid for" + " " + post.monthvalidate + " " + "Months"}</h4>
                                            <a className="button">SELECT PACKAGE</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w3layouts-footerdesign">
                    <div className="footerdesign">
                        <img src="{ require('../../assests/images/bg.png') }" alt="" />
                    </div>
                    <div className="clearfix" />
                </div>
            </section>

            <section className="aboutdetails">
                <div className="container">
                    <div className="row">
                        <div className="text-center aboutdetail">
                            <h2>Membership Registration Fee</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={member_image} />
                        </div>
                        <div className="col-md-6 content">
                            {member_content}
                        </div>
                    </div>
                </div>
            </section>


            {/*//details*/}
            {/* our packages*/}

        </div>







    )
}

export default Member