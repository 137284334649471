import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextareaAutosize } from '@material-ui/core';
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Tabp from '../../ui/tabpanel'



import isEmpty from "../../lib/isEmpty";
import { getregister, updateregister} from "../action/user";

import { getreligionlist, getregislist, getcastelist, getmaritallist, getcolorlist, getstarlist, getzodiaclist, getdirectionlist } from "../action/user";

const style = {

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #ff6ed',
    boxShadow: 24,
    p: 4,
    borderRadius: '25px'
  
    
  };

const useStyles = makeStyles((theme) => ({
    InputLabel: {
        color: "rgb(0,0,0)",
        fontFamily: 'Source Sans Pro'
    },
    textDanger: {
        color:"#950695",
        fontSize: "13px"
    },
    formControl: {
        
        margin: theme.spacing(1),
        minWidth: 120
       
      },
    //   MuiAutocomplete:{
    //     fontSize:"16px"
    //   },  

   
}));

// toaster config
toast.configure();
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const initialFormValue = {
    // personal detail
    phone_number: "",
    mname: "",
    religion: "",
    subcaste: "",
    height: "",
    educ: "",
    w_place: "",
    different: "",
    handireason: "",
    bestgod: "",
    date: "",
    month: "",
    year: "",
    sex: "",
    castename: "",
    birth_time: "",
    occup: "",
    income: "",
    children: "",
    child_detail: "",
    colorname: "",
    maritalname: "",
    n_marriage:"",
    castenone:"",
    // Family detail
    father_name: "",
    mother_name: "",
    dad_work: "",
    mom_work: "",
    brother: "",
    m_brother: "",
    sister: "",
    m_sister: "",
    regis: "",
    puriveaam: "",
    livesity: "",
    dever: "",
    peroparty: "",
    pob: "",

    // horscope detail

    starname: "",
    patham: "",
    zodiacname: "",
    gothra: "",
    directionname: "",
    s_year: "",
    s_month: "",
    s_day: "",
    r1: "",
    r2: "",
    r3: "",
    r4: "",
    r5: "",
    r6: "",
    r7: "",
    r8: "",
    r9: "",
    r10: "",
    r11: "",
    r12: "",
    a1: "",
    date: "",
    month: "",
    year: "",
    a5: "",
    a6: "",
    a7: "",
    a8: "",
    a9: "",
    a10: "",
    a11: "",
    a12: "",
    //contact details

    address: "",
    city: "",
    mobile_number: "",
    email_id: "",
    comment: "",

};


export default function UserProfile() {
    const classes = useStyles();
    const history = useNavigate();
    // const dispatch = useDispatch();
    const [userdet, setUser] = useState();
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const { registerId } = useParams();  


    const [options, setoption] = useState();
    const [colors, setcolor] = useState();

    const [religions, setreligion] = useState();
    const [castes, setcaste] = useState();
    const [maritals, setmarital] = useState();
    const [stars, setstar] = useState();
    const [zodiacs, setzodiac] = useState();
    const [directions, setdirection] = useState();
    
    const [childdetail, setchild] = useState("none");
    const [remarriage, setUnmarried] = useState("none");

    const [handyreason, setdifferent] = useState("none");









    const handleFile = (event) => {
        event.preventDefault();
        
        // console.log(event.target.files[0]);
        const { id, files } = event.target;
        //settmpupimagefront(URL.createObjectURL(event.target.files[0]));
        let formData = { ...formValue, ...{ [id]: files[0] } };
        setFormValue(formData);
        //setValidateError(formData)
    };


    // function
    const onChange = (e) => {
        e.preventDefault();
        // var testAbdul = convertThis(event,numchar);
        
        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);
        
        // console.log(formValue);
        //setValidateError(formData)
    };
    const dayoption = [];
    for (let i = 1; i <= 31; i++) {
        dayoption.push(i);
    }

    const monthoption = [];
    for (let i = 1950; i <= 2030; i++) {
        monthoption.push(i);
    }

    const {
        phone_number,
        mname,
        religion,
        subcaste,
        height,
        educ,
        w_place,
        different,
        handireason,
        bestgod,
        date,
        month,
        year,
        sex,
        castename,
        birth_time,
        occup,
        income,
        children,
        child_detail,
        colorname,
        maritalname,
        n_marriage,
        castenone,
        // family detail
        father_name,
        mother_name,
        dad_work,
        mom_work,
        brother,
        m_brother,
        sister,
        m_sister,
        regis,
        puriveaam,
        livesity,
        dever,
        peroparty,
        pob,

        //Horscope DetailssetRegisterfilters
        starname,
        patham,
        zodiacname,
        gothra,
        directionname,
        s_year,
        s_month,
        s_day,
        r1,
        r2,
        r3,
        r4,
        r5,
        r6,
        r7,
        r8,
        r9,
        r10,
        r11,
        r12,
        a1,
        a2,
        a3,
        a4,
        a5,
        a6,
        a7,
        a8,
        a9,
        a10,
        a11,
        a12,

        //contact detail
        address,
        city,
        photopath,
        jathagampic,
        mobile_number,
        email_id,
        comment,
        age,

    } = formValue;

    const handleFormSubmit = async (e) => {
        //
        // console.log("saran");
        e.preventDefault();
        
        // console.log(formValue);
        let reqData = {
            phone_number,
            mname,
            religion,
            subcaste,
            height,
            educ,
            w_place,
            different,
            handireason,
            bestgod,
            date,
            month,
            year,
            sex,
            castename,
            birth_time,
            occup,
            income,
            children,
            child_detail,
            colorname,
            maritalname,
            n_marriage,
            castenone,
            // family detail
            father_name,
            mother_name,
            dad_work,
            mom_work,
            brother,
            m_brother,
            sister,
            m_sister,
            regis,
            puriveaam,
            livesity,
            dever,
            peroparty,
            pob,

            //  Horscope Details

            starname,
            patham,
            zodiacname,
            gothra,
            directionname,
            s_year,
            s_month,
            s_day,
            r1,
            r2,
            r3,
            r4,
            r5,
            r6,
            r7,
            r8,
            r9,
            r10,
            r11,
            r12,
            a1,
            a2,
            a3,
            a4,
            a5,
            a6,
            a7,
            a8,
            a9,
            a10,
            a11,
            a12,


            //contact detailshandleO
            city,
            photopath,
            jathagampic,
            mobile_number,
            email_id,
            comment,
            registerId,
            //age = new Date().getFullYear()-year,
        };
        
        // console.log("req", reqData);
        let { error } = await updateregister(reqData);
        
        // console.log("error", error);
        if (isEmpty(error)) {
            toast.success("User added", toasterOption);
            // history.push("/");
            window.location = "/";
        } else {
            setValidateError(error);
        }
    }

    const brothers = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
        { title: "5", value: "5" },
        { title: "6", value: "6" },
        { title: "7", value: "7" },
        { title: "8", value: "8" },
        { title: "9", value: "9" },
        { title: "10", value: "10" },
        { title: "11", value: "11" },
        { title: "12", value: "12" },
    ];

    const mbrothers = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
        { title: "5", value: "5" },
        { title: "6", value: "6" },
        { title: "7", value: "8" },
        { title: "9", value: "9" },
        { title: "10", value: "10" },
        { title: "11", value: "11" },
        { title: "12", value: "12" },
    ];

    const sisters = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
        { title: "5", value: "5" },
        { title: "6", value: "6" },
        { title: "7", value: "7" },
        { title: "8", value: "8" },
        { title: "9", value: "9" },
        { title: "10", value: "10" },
        { title: "11", value: "11" },
        { title: "12", value: "12" },
    ];

    const msisters = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
        { title: "5", value: "5" },
        { title: "6", value: "6" },
        { title: "7", value: "7" },
        { title: "8", value: "8" },
        { title: "9", value: "9" },
        { title: "10", value: "10" },
        { title: "11", value: "11" },
        { title: "12", value: "12" },
    ];

    const heights = [
        { title: "4ft 5in", value: "4ft 5in" },
        { title: "4ft 6in", value: "4ft 6in" },
        { title: "4ft 7in", value: "4ft 7in" },
        { title: "4ft 8in", value: "4ft 8in" },
        { title: "4ft 9in", value: "4ft 9in" },
        { title: "4ft 10in", value: "4ft 10in" },
        { title: "4ft 11in", value: "4ft 11in" },
        { title: "5ft", value: "5ft" },
        { title: "5ft 1in", value: "5ft 1in" },
        { title: "5ft 2in", value: "5ft 2in" },
        { title: "5ft 3in", value: "5ft 3in" },
        { title: "5ft 4in", value: "5ft 4in" },
        { title: "5ft 5in", value: "5ft 5in" },
        { title: "5ft 6in", value: "5ft 6in" },
        { title: "5ft 7in", value: "5ft 7in" },
        { title: "5ft 8in", value: "5ft 8in" },
        { title: "5ft 9in", value: "5ft 9in" },
        { title: "5ft 10in", value: "5ft 10in" },
        { title: "5ft 11in", value: "5ft 11in" },
        { title: "6ft", value: "6ft" },
        { title: "6ft 1in", value: "6ft 1in" },
        { title: "6ft 2in", value: "6ft 2in" },
        { title: "6ft 3in", value: "6ft 3in" },
        { title: "6ft 4in", value: "6ft 4in" },
        { title: "6ft 5in", value: "6ft 5in" },
        { title: "6ft 6in", value: "6ft 6in" },
        { title: "6ft 7in", value: "6ft 7in" },
        { title: "6ft 8in", value: "6ft 8in" },
        { title: "6ft 9in", value: "6ft 9in" },
        { title: "6ft 10in", value: "6ft 10in" },
        { title: "6ft 11in", value: "6ft 11in" },
        { title: "7ft", value: "7ft" },
    ];

    const sexch = [
        { title: "Male", value: "Male" },
        { title: "Female", value: "Female" },
    ];

    const differents = [
        { title: "Yes", value: "Yes" },
        { title: "No", value: "No" },
    ];

    const pathams = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
    ];

    const nmarriage = [
        { title: "Divorce", value: "Divorce" },
        { title: "Widow", value: "Widow" },
      ];

      const childrens = [
        { title: "Yes", value: "Yes" },
        { title: "No", value: "No" },
      ];  

      const castenahi = [
        { title: "Yes", value: "Yes" },
        { title: "No", value: "No" },
      ];

    const getUserData = async () => {
        // 
        // console.log(userId);
        var test = await getreligionlist();
        // 
        // console.log("fdssfdsfdsf", test);
        const option1 = await test.userValue.map(d => ({
            "title": d.religion,
            "value": d.religion
        }));
        setreligion(option1);

        var test = await getmaritallist();
        // 
        // console.log("fdssfdsfdsf", test);
        const maritals = await test.userValue.map(d => ({
            "title": d.maritalname,
            "value": d.maritalname
        }));
        setmarital(maritals);

        var test = await getcastelist();
        // 
        // console.log("fdssfdsfdsf", test);
        const castes = await test.userValue.map(d => ({
            "title": d.castename,
            "value": d.castename
        }));
        setcaste(castes);

        var test = await getregislist();
        // 
        // console.log("fdssfdsfdsf", test);
        const option = await test.userValue.map(d => ({
            "title": d.regis,
            "value": d.regis
        }));
        setoption(option);

        var test = await getcolorlist();
        // 
        // console.log("fdssfdsfdsf", test);
        const colors = await test.userValue.map(d => ({
            "title": d.colorname,
            "value": d.colorname
        }));
        setcolor(colors);

        var test = await getstarlist();
        // 
        // console.log("fdssfdsfdsf", test);
        const stars = await test.userValue.map(d => ({
            "title": d.starname,
            "value": d.starname
        }));
        setstar(stars);

        var test = await getzodiaclist();
        // 
        // console.log("fdssfdsfdsf", test);
        const zodiacs = await test.userValue.map(d => ({
            "title": d.zodiacname,
            "value": d.zodiacname
        }));
        setzodiac(zodiacs);

        var test = await getdirectionlist();
        // 
        // console.log("fdssfdsfdsf", test);
        const directions = await test.userValue.map(d => ({
            "title": d.directionname,
            "value": d.directionname
        }));
        setdirection(directions);
        //setUser(test.userValue);


        var test = await getregister(registerId);
        
        // console.log("fdssfdsfdsf", test);
        let formdata = {};
        formdata["phone_number"] = test.userValue.phone_number;
        formdata["mname"] = test.userValue.mname;
        formdata["religion"] = test.userValue.religion;
        formdata["subcaste"] = test.userValue.subcaste;
        formdata["height"] = test.userValue.height;
        formdata["educ"] = test.userValue.educ;
        formdata["w_place"] = test.userValue.w_place;
        formdata["different"] = test.userValue.different;
        formdata["handireason"] = test.userValue.handireason;
        formdata["bestgod"] = test.userValue.bestgod;
        formdata["date"] = test.userValue.date;
        formdata["month"] = test.userValue.month;
        formdata["year"] = test.userValue.year;
        formdata["sex"] = sexch.find(o => o.value === test.userValue.sex);
        formdata["castename"] = castes.find(o => o.value === test.userValue.castename);
        formdata["birth_time"] = test.userValue.birth_time;
        formdata["occup"] = test.userValue.occup;
        formdata["income"] = test.userValue.income;
        formdata["children"] = test.userValue.children;
        formdata["child_detail"] = test.userValue.child_detail;
        formdata["complexion"] = test.userValue.complexion;
        formdata["maritalname"] = test.userValue.maritalname;
        formdata["colorname"] = test.userValue.colorname;
        formdata["n_marriage"] = test.userValue.n_marriage;   
        //   family detail
        formdata["father_name"] = test.userValue.father_name;
        formdata["mother_name"] = test.userValue.mother_name;
        formdata["dad_work"] = test.userValue.dad_work;
        formdata["mom_work"] = test.userValue.mom_work;
        formdata["brother"] = test.userValue.brother;
        formdata["m_brother"] = test.userValue.m_brother;
        formdata["sister"] = test.userValue.sister;
        formdata["m_sister"] = test.userValue.m_sister;
        formdata["regis"] = test.userValue.regis;
        formdata["puriveaam"] = test.userValue.puriveaam;
        formdata["livesity"] = test.userValue.livesity;
        formdata["dever"] = test.userValue.dever;
        formdata["peroparty"] = test.userValue.peroparty;
        formdata["pob"] = test.userValue.pob;
           
        //horscope details
    
        formdata["zodiacname"] = test.userValue.zodiacname;
        formdata["directionname"] = test.userValue.directionname;
        formdata["starname"] = test.userValue.starname;
        formdata["patham"] = test.userValue.patham;
        formdata["moonsign"] = test.userValue.moonsign;
        formdata["gothra"] = test.userValue.gothra;
        formdata["stre_face"] = test.userValue.stre_face;
        formdata["s_year"] = test.userValue.s_year;
        formdata["s_month"] = test.userValue.s_month;
        formdata["s_day"] = test.userValue.s_day;
        formdata["r1"] = test.userValue.r1;
        formdata["r2"] = test.userValue.r2;
        formdata["r3"] = test.userValue.r3;
        formdata["r4"] = test.userValue.r4;
        formdata["r5"] = test.userValue.r5;
        formdata["r6"] = test.userValue.r6;
        formdata["r7"] = test.userValue.r7;
        formdata["r8"] = test.userValue.r8;
        formdata["r9"] = test.userValue.r9;
        formdata["r10"] = test.userValue.r10;
        formdata["r11"] = test.userValue.r11;
        formdata["r12"] = test.userValue.r12;
        formdata["a1"] = test.userValue.a1;
        formdata["a2"] = test.userValue.a2;
        formdata["a3"] = test.userValue.a3;
        formdata["a4"] = test.userValue.a4;
        formdata["a5"] = test.userValue.a5;
        formdata["a6"] = test.userValue.a6;
        formdata["a7"] = test.userValue.a7;
        formdata["a8"] = test.userValue.a8;
        formdata["a9"] = test.userValue.a9;
        formdata["a10"] = test.userValue.a10;
        formdata["a11"] = test.userValue.a11;
        formdata["a12"] = test.userValue.a12;
    
         //  contact details
        formdata["comment"] = test.userValue.comment;
        formdata["address"] = test.userValue.address;
        formdata["city"] = test.userValue.city;
        formdata["photofile"] = test.userValue.photopath;
        formdata["photofile"] = test.userValue.jathagampic;
        formdata["mobile_number"] = test.userValue.mobile_number;
        formdata["email_id"] = test.userValue.email_id;
        setFormValue(formdata);
    };

    const add = async () => {
        window.location = "/crm/registeradden";
    };


    useEffect(() => {
        //logout(history)
        getUserData();
    }, []);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div>
        <section className="inner-page-banners">
            <img className="images img-responsive" src={require('../../assests/images/87295banner.png')} />
            <div className="clearfix" />
        </section>

        <div className="container-fluid register-front-end">
            <section style={{ boxShadow: '0px 3px 23px 0px rgb(244, 246, 249)' }}>
                <div className="container about-us">
                    <div className="contact-banner-heading text-center col-md-offset-4 col-md-3">
                        <div id="triangle_left" />
                        <p>Free Registration </p>
                        <div id="triangle_right" />
                    </div>
                </div></section>
            <div className="content">
                <div className="reg-top-links">
                    <div className="col-md-6">
                        <h6>Already Registered ? <span> <Button onClick={handleOpen} style={{ color:"#7d2a93", fontWeight:"800", fontSize:"13px" }}>Login now</Button> 
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} >
                    
                    <Tabp />

                  </Box>
                </Modal>
                </span></h6>
                    </div>
                    {/*<div class="col-md-6" style="text-align: right;">*/}
                    {/*<a href="/registers/engregister" style="color: #6a2d91;">Free English Registeration</a>*/}
                    {/* </div>*/}
                    <h4 className="text-center" style={{ color: '#e9018b' }}>  Register to Meet Your Life Partner</h4>
                </div>
                <div className="panel panel-white">
                    <form className={classes.form}
                        noValidate
                        onSubmit={handleFormSubmit}>
                        <h6 className="form-wizard-title text-semibold">
                            <p>சொந்த விவரங்கள்</p>
                        </h6>
                        <div className="row register-row">
                            <div className="col-md-6" style={{ paddingRight: '30px' }}>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>கைபேசி எண்  <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control validate[required,custom[onlyNumberSp],minSize[10],maxSize[10]]"
                                            value={phone_number} id="phone_number" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} onChange={onChange} name="data[Register][phone_number]" />
                                          
                                            {validateError.phone_number && (
                      <span style={{ color:"#950695", fontSize:"13px" }}className={classes.textDanger}>
                        {validateError.phone_number}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label> பெயர் <span className="reguired">*</span></label>
                                    </div>
                                     <div className="col-md-8"><input onkeypress="javascript:convertThis(event);" onChange={onChange} type="text" id="mname" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={mname} className="form-control validate[required]" name="data[Register][mname]" /></div>
                                     {validateError.mname && (
                      <span style={{ color:"#950695", fontSize:"13px" }} className={classes.textDanger}>
                        {validateError.mname}
                      </span>
                    )}
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label> மதம் <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={religions}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                               
                                                
                                                // console.log(newValue.value);
                                                if (newValue.value) {
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["religion"]: newValue.value },
                                                    };
                                                    
                                                    // console.log(formData, "sdgfgs");
                                                    setFormValue(formData);
                                                }
                                            }}
                                            type="text"
                                            name="religion"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                     label="Religion"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                         {validateError.religion && (
                      <span className={classes.textDanger}>
                        {validateError.religion}
                      </span>
                    )}
                                        
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>உட்பிரிவு </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" onChange={onChange} id="subcaste" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={subcaste} name="data[Register][subcaste]" onkeypress="javascript:convertThis(event);" />
                                        {validateError.subcaste && (
                      <span className={classes.textDanger}>
                        {validateError.subcaste}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>உயரம் <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={heights}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["height"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="height"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Height "
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.height && (
                      <span className={classes.textDanger}>
                        {validateError.height}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>கல்வி தகுதி   <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control validate[required]" onChange={onChange} id="educ" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={educ} name="data[Register][educ]" />
                                        {validateError.educ && (
                      <span className={classes.textDanger}>
                        {validateError.educ}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>பணிபுரியுமிடம்</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][w_place]" onChange={onChange} id="w_place" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={w_place} onkeypress="javascript:convertThis(event);" />
                                        {validateError.w_place && (
                      <span className={classes.textDanger}>
                        {validateError.w_place}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>மாற்றுத் திறனாளி<spabel>
                                        </spabel></label></div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={differents}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                if (newValue.value == "No") {

                                                    setdifferent("none");
                    
                                                  } else {
                                                    setdifferent("block");
                                                  }
                                                
                                                //   console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["different"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="different"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Differently abled"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.different && (
                      <span className={classes.textDanger}>
                        {validateError.different}
                      </span>
                    )}

                                    </div>
                                </div>
                                <div className="form-group row handicap" style={{ display:handyreason}}>
                                    <div className="col-md-4">
                                        <label>குறிப்பிடுக:</label>
                                    </div>
                                   <div className="col-md-8">
                                        <textarea className="form-control" onChange={onChange} id="handireason" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={handireason} name="data[Register][handireason]" onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                        {validateError.handireason && (
                      <span className={classes.textDanger}>
                        {validateError.handireason}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>குலதெய்வம்<spabel>
                                        </spabel></label></div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][bestgod]" onChange={onChange} id="bestgod" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={bestgod} onkeypress="javascript:convertThis(event);" />
                                        {validateError.bestgod && (
                      <span className={classes.textDanger}>
                        {validateError.bestgod}
                      </span>
                    )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ paddingLeft: '30px' }}>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>பிறந்த தேதி <span className="reguired">*</span></label>
                                    </div>
                                    <div className="form-group col-md-8 row dob" style={{ paddingRight: 'unset' }}>
                                        <div className="col-md-4" style={{ paddingRight: 'unset' }}>

                                            <InputLabel className={classes.InputLabel} >நாள்</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="date"
                                                value={date}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    
                                                    // console.log(e.target);
                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["date"]: value } };
                                                    setFormValue(formData);
                                                    
                                                    // console.log(formValue);
                                                    //setValidateError(formData)
                                                }}


                                            >
                                                <MenuItem aria-label="None" value="" />
                                                {dayoption.map(date => (
                                                    <MenuItem value={date}>
                                                        {date}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {validateError.date && (
                                               <span className={classes.textDanger}>
                                             {validateError.date}
                                         </span>
                                        )}

                                        </div>
                                        <div className="col-md-4" style={{ paddingRight: 'unset' }}>

                                            <InputLabel className={classes.InputLabel} id="age-native-required">மாதம்</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="month"
                                                value={month}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    
                                                    // console.log(e.target);
                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["month"]: value } };
                                                    setFormValue(formData);
                                                    
                                                    // console.log(formValue);
                                                    //setValidateError(formData)
                                                }}

                                            >
                                                <MenuItem aria-label="None" value="" />
                                                <MenuItem value={1}>January</MenuItem>
                                                <MenuItem value={2}>February</MenuItem>
                                                <MenuItem value={3}>March</MenuItem>
                                                <MenuItem value={4}>April</MenuItem>
                                                <MenuItem value={5}>May</MenuItem>
                                                <MenuItem value={6}>June</MenuItem>
                                                <MenuItem value={7}>July</MenuItem>
                                                <MenuItem value={8}>August</MenuItem>
                                                <MenuItem value={9}>September</MenuItem>
                                                <MenuItem value={10}>October</MenuItem>
                                                <MenuItem value={11}>November</MenuItem>
                                                <MenuItem value={12}>December</MenuItem>
                                            </Select>
                                            {validateError.month && (
<span className={classes.textDanger}>
  {validateError.month}
</span>
)}

                                        </div>
                                        <div className="col-md-4" style={{ paddingRight: 'unset' }}>

                                            <InputLabel className={classes.InputLabel} id="age-native-required">வருடம்</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="year"
                                                value={year}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    
                                                    // console.log(e.target);
                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["year"]: value } };
                                                    setFormValue(formData);
                                                    
                                                    // console.log(formValue);
                                                    //setValidateError(formData)
                                                }}

                                            >
                                                <MenuItem aria-label="None" value="" />
                                                {monthoption.map(year => (
                                                    <MenuItem value={year}>
                                                        {year}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {validateError.year && (
<span className={classes.textDanger}>
  {validateError.year}
</span>
)}

                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label> பாலினம்  <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        
                                        <Autocomplete
                                            options={sexch}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);

                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["sex"]: newValue.value },
                                                };
                                                setFormValue(formData);

                                            }}
                                            type="text"
                                            name="sex"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="sex"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.sex && (
<span className={classes.textDanger}>
{validateError.sex}
</span>
)}
                                    </div>
                                    {/* <div className="col-md-8">
                                        <select className="form-control  validate[required]" value={sex} id="sex" name="data[Register][sex]">
                                            <option value="Male">Male&nbsp;&nbsp;</option>
                                            <option value="Female">&nbsp;Female</option>
                                        </select>
                                    </div> */}
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label> இனம்  <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={castes}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                // 
                                                // console.log("caste", newValue.value);
                                                if (newValue.value) {
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["castename"]: newValue.value },
                                                    };
                                                    setFormValue(formData);

                                                }
                                            }}
                                            type="text"
                                            name="castename"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                      label="Caste "
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                          {validateError.castename && (
                      <span className={classes.textDanger}>
                        {validateError.castename}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>பிறந்த நேரம் </label>
                                    </div>
                                    <div className="col-md-8"><input type="text" className="form-control" onChange={onChange} id="birth_time" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={birth_time} name="data[Register][birth_time]" /></div>
                                    {validateError.birth_time && (
                      <span className={classes.textDanger}>
                        {validateError.birth_time}
                      </span>
                    )}
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>நிறம்</label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={colors}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                // 
                                                // console.log(newValue.value);
                                                if (newValue.value) {
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["colorname"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }
                                            }}
                                            type="text"
                                            name="colorname"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Complexion(color)"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.colorname && (
                      <span className={classes.textDanger}>
                        {validateError.colorname}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>வேலை, தொழில்   <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control validate[required]" onChange={onChange} id="occup" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={occup} name="data[Register][occup]" onkeypress="javascript:convertThis(event);" />
                                        {validateError.occup && (
                      <span className={classes.textDanger}>
                        {validateError.occup}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>சம்பள வருமானம் </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" onChange={onChange} id="income" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={income} name="data[Register][income]" />
                                        {validateError.income && (
                      <span className={classes.textDanger}>
                        {validateError.income}
                      </span>
                    )}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>திருமண நிலை <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={maritals}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                // 
                                                // console.log(newValue.value,"marital");
                                                if (newValue.value == "Marriage") {

                                                    setUnmarried("none");

                                                  } else {
                                                    setUnmarried("block");
                                                  }
                                                
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["maritalname"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                                
                                            }}
                                            type="text"
                                            name="maritalname"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                      label="Marital Status"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                         {validateError.maritalname && (
                      <span className={classes.textDanger}>
                        {validateError.maritalname}
                      </span>
                    )}
                                    </div>   

                                </div>
                                <div className="form-group row children" style={{ display: remarriage }}>
                                    <div className="col-md-4">
                                        <label> மறுமணம் காரணம்</label>
                                    </div>
                                    <div className="col-md-8">
                                    <Autocomplete
                      options={nmarriage}
                      // noOptionsText="Enter to create a new option"
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) => {
                        
                        // console.log(newValue.value);
                        let formData = {
                          ...formValue,
                          ...{ ["n_marriage"]: newValue.value },
                        };
                        setFormValue(formData);
                      }}
                      type="text"
                      name="nmarriage"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Remarriage reason"
                          variant="outlined"
                        />
                      )}
                    />

                    {validateError.n_marriage && (
                      <span className={classes.textDanger}>
                        {validateError.n_marriage}
                      </span>
                    )}
                                    </div>
                                </div>
          

                                <div className="form-group row children" style={{ display: remarriage }}>
                                    <div className="col-md-4">
                                        <label>குழந்தைகள்</label>
                                    </div>
                                    <div className="col-md-8">
                                    <Autocomplete
                      options={childrens}
                      // noOptionsText="Enter to create a new option"
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) => {

                        if (newValue.value == "No") {

                            setchild("none");

                          } else {
                            setchild("block");
                          }
                        
                        //   console.log(newValue.value);
                        let formData = {
                          ...formValue,
                          ...{ ["children"]: newValue.value },
                        };
                        setFormValue(formData);
                      }}
                      type="text"
                      name="children"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                           label="children"
                          variant="outlined"
                        />
                      )}
                    />

                    {validateError.children && (
                      <span className={classes.textDanger}>
                        {validateError.children}
                      </span>
                    )}
                                    </div>
                                </div>





                   
                                    <div className="form-group row children" style={{ display: childdetail }}>
                                    <div className="col-md-4">
                                        <label>குழந்தைகள் விவரங்கள்
                                        </label></div>
                                    <div className="col-md-8">
                                        <textarea className="form-control" onChange={onChange} id="child_detail" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={child_detail} name="data[Register][child_detail]" onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                        {validateError.child_detail && (
                      <span className={classes.textDanger}>
                        {validateError.child_detail}
                      </span>
                    )}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label> ஜாதி தடை இல்லை</label>
                                    </div>
                                    <div className="col-md-8">
                                        
                                        <Autocomplete
                                            options={castenahi}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);

                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["castenone"]: newValue.value },
                                                };
                                                setFormValue(formData);

                                            }}
                                            type="text"
                                            name="castenone"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="ஜாதி தடை இல்லை"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.castenone && (
<span className={classes.textDanger}>
{validateError.castenone}
</span>
)}
                                    </div>
                                    
                                </div>
                                   
                                         
                            </div>
                        </div>
                        <h6 className="form-wizard-title text-semibold">
                            <p> குடும்ப விவரங்கள் </p>
                        </h6>
                        <div className="row register-row">
                            <div className="col-md-6" style={{ paddingRight: '30px' }}>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>தந்தையின் பெயர் <span className="reguired">*</span><label>
                                        </label></label></div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" onChange={onChange} id="father_name" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={father_name} name="data[Register][father_name]" onkeypress="javascript:convertThis(event);" />
                                        {validateError.father_name && (
                      <span className={classes.textDanger}>
                        {validateError.father_name}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>தந்தையின் வேலை <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input className="form-control" name="data[Register][dad_work]" onChange={onChange} id="dad_work" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={dad_work} onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                        {validateError.dad_work && (
                      <span className={classes.textDanger}>
                        {validateError.dad_work}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>சகோதரர்கள் <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={brothers}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["brother"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="brother"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                     label="Brother's"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.brother && (
                      <span className={classes.textDanger}>
                        {validateError.brother}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>சகோதரிகள் <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={sisters}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["sister"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="sister"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                     label="Sister's"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                         {validateError.sister && (
                      <span className={classes.textDanger}>
                        {validateError.sister}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>பதிவுசெய்பவர்</label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={options}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["regis"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="regis"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Created By"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.regis && (
                      <span className={classes.textDanger}>
                        {validateError.regis}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>இருப்பிடம் <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][livesity]" onChange={onChange}
                                            value={livesity} id="livesity" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} onkeypress="javascript:convertThis(event);" />
                                             {validateError.livesity && (
                      <span className={classes.textDanger}>
                        {validateError.livesity}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>சொத்துக்கள்</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][peroparty]" onChange={onChange}
                                            value={peroparty} id="peroparty" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} onkeypress="javascript:convertThis(event);" />
                                            {validateError.peroparty && (
                      <span className={classes.textDanger}>
                        {validateError.peroparty}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>எதிர்பார்ப்பு</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][pob]" onChange={onChange}
                                            value={pob} id="pob" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} onkeypress="javascript:convertThis(event);" />
                                             {validateError.pob && (
                      <span className={classes.textDanger}>
                        {validateError.pob}
                      </span>
                    )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ paddingLeft: '30px' }}>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>தாய் பெயர் <span className="reguired">*</span><label>
                                        </label></label></div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" onChange={onChange} id="mother_name" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={mother_name} name="data[Register][mother_name]" onkeypress="javascript:convertThis(event);" />
                                        {validateError.mother_name && (
                      <span className={classes.textDanger}>
                        {validateError.mother_name}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>தாயின் வேலை</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input className="form-control" name="data[Register][mom_work]" onChange={onChange} id="mom_work" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={mom_work} onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                        {validateError.mom_work && (
                      <span className={classes.textDanger}>
                        {validateError.mom_work}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>திருமணமான சகோதரர்கள் <span className="reguired">*</span> </label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={mbrothers}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["m_brother"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="m_brother"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                     label="Married Brother's"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.m_brother && (
                        <span className={classes.textDanger}>
                          {validateError.m_brother}
                        </span>
                      )}

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>திருமணமான சகோதரிகள் <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={msisters}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["m_sister"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="m_sister"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                     label="Married Sister's"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                         {validateError.m_sister && (
                        <span className={classes.textDanger}>
                          {validateError.m_sister}
                        </span>
                      )}

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>பூர்வீகம்</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][puriveaam]" onChange={onChange}
                                            value={puriveaam} id="puriveaam" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} onkeypress="javascript:convertThis(event);" />
                                            {validateError.puriveaam && (
                        <span className={classes.textDanger}>
                          {validateError.puriveaam}
                        </span>
                      )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>சீர்வரிசை</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][dever]" onChange={onChange}
                                            value={dever} id="dever"style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} onkeypress="javascript:convertThis(event);" />
                                            {validateError.dever && (
                        <span className={classes.textDanger}>
                          {validateError.dever}
                        </span>
                      )}  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 className="form-wizard-title text-semibold">
                            <p>ஜாதக விவரங்கள்</p>
                        </h6>
                        <div className="row register-row" style={{ paddingBottom: '0px' }}>
                            <div className="col-md-4" style={{ paddingRight: '30px' }}>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>நட்சத்திரம் <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={stars}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                // 
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["starname"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="starname"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                     label="Star(Nakshatra)"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                          {validateError.starname && (
                        <span className={classes.textDanger}>
                          {validateError.starname}
                        </span>
                      )}  
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>திசை இருப்பு </label>
                                    <Autocomplete
                                        options={directions}
                                        // noOptionsText="Enter to create a new option"
                                        getOptionLabel={(option) => option.title}
                                        onChange={(event, newValue) => {
                                            
                                            // console.log(newValue.value);
                                            let formData = {
                                                ...formValue,
                                                ...{ ["directionname"]: newValue.value },
                                            };
                                            setFormValue(formData);
                                        }}
                                        type="text"
                                        name="directionname"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Directions"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    {validateError.directionname && (
                        <span className={classes.textDanger}>
                          {validateError.directionname}
                        </span>
                      )}  

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>பாதம்</label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={pathams}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["patham"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="patham"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                     label="paatham"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.patham && (
                        <span className={classes.textDanger}>
                          {validateError.patham}
                        </span>
                      )}

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label>லக்னம்</label>
                                    <Autocomplete
                                        options={zodiacs}
                                        // noOptionsText="Enter to create a new option"
                                        getOptionLabel={(option) => option.title}
                                        onChange={(event, newValue) => {
                                            
                                            // console.log(newValue.value);
                                            let formData = {
                                                ...formValue,
                                                ...{ ["gothra"]: newValue.value },
                                            };
                                            setFormValue(formData);
                                        }}
                                        type="text"
                                        name="gothra"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                 label="Laknam"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    {validateError.gothra && (
                        <span className={classes.textDanger}>
                          {validateError.gothra}
                        </span>
                      )}

                                </div>
                            </div>
                            <div className="col-md-4" style={{ paddingLeft: '30px' }}>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label> ராசி <span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <Autocomplete
                                            options={zodiacs}
                                            // noOptionsText="Enter to create a new option"
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                
                                                // console.log(newValue.value);
                                                let formData = {
                                                    ...formValue,
                                                    ...{ ["zodiacname"]: newValue.value },
                                                };
                                                setFormValue(formData);
                                            }}
                                            type="text"
                                            name="zodiacname"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                  label="Zodiac (Moon Singh)"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {validateError.zodiacname && (
                        <span className={classes.textDanger}>
                          {validateError.zodiacname}
                        </span>
                      )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <InputLabel className={classes.InputLabel} >வருடம்</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="s_year"
                                            value={s_year}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                
                                                // console.log(e.target);
                                                const { id, value } = e.target;
                                                let formData = { ...formValue, ...{ ["s_year"]: value } };
                                                setFormValue(formData);
                                                
                                                // console.log(formValue);
                                                //setValidateError(formData)
                                            }}

                                        >
                                            <MenuItem aria-label="None" value="" />
                                            {monthoption.map(s_year => (
                                                <MenuItem value={s_year}>
                                                    {s_year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {validateError.s_year && (
                        <span className={classes.textDanger}>
                          {validateError.s_year}
                        </span>
                      )}
                                    </div>
                                    <div className="col-md-4">
                                        <InputLabel className={classes.InputLabel} id="age-native-required">மாதம்</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="s_month"
                                            value={s_month}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                
                                                // console.log(e.target);
                                                const { id, value } = e.target;
                                                let formData = { ...formValue, ...{ ["s_month"]: value } };
                                                setFormValue(formData);
                                                
                                                // console.log(formValue);
                                                //setValidateError(formData)
                                            }}

                                        >
                                            <MenuItem aria-label="None" value="" />
                                            <MenuItem value={1}>January</MenuItem>
                                            <MenuItem value={2}>February</MenuItem>
                                            <MenuItem value={3}>March</MenuItem>
                                            <MenuItem value={4}>April</MenuItem>
                                            <MenuItem value={5}>May</MenuItem>
                                            <MenuItem value={6}>June</MenuItem>
                                            <MenuItem value={7}>July</MenuItem>
                                            <MenuItem value={8}>August</MenuItem>
                                            <MenuItem value={9}>September</MenuItem>
                                            <MenuItem value={10}>October</MenuItem>
                                            <MenuItem value={11}>November</MenuItem>
                                            <MenuItem value={12}>December</MenuItem>
                                        </Select>
                                        {validateError.s_month && (
                        <span className={classes.textDanger}>
                          {validateError.s_month}
                        </span>
                      )}
                                    </div>
                                    <div className="col-md-4">
                                        <InputLabel className={classes.InputLabel} id="age-native-required">நாள்</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="s_day"
                                            value={s_day}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                
                                                // console.log(e.target);
                                                const { id, value } = e.target;
                                                let formData = { ...formValue, ...{ ["s_day"]: value } };
                                                setFormValue(formData);
                                                
                                                // console.log(formValue);
                                                //setValidateError(formData)
                                            }}

                                        >
                                            <MenuItem aria-label="None" value="" />
                                            {dayoption.map(s_day => (
                                                <MenuItem value={s_day}>
                                                    {s_day}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {validateError.s_day && (
                        <span className={classes.textDanger}>
                          {validateError.s_day}
                        </span>
                      )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className style={{ marginTop: '30px' }}>
                            <img src="https://www.thirumagalmatrimonymadurai.in/app/webroot//img/chat_NEW.png" className="img-responsive" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                            <div className="form-group table-responsive">
                                <table className="rassi-table" style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px' }}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r1}
                                                    id="r1"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r2}
                                                    id="r2"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r3}
                                                    id="r3"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r4}
                                                    id="r4"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <div></div>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a1}
                                                    id="a1"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a2}
                                                    id="a2"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a3}
                                                    id="a3"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a4}
                                                    id="a4"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r5}
                                                    id="r5"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td colspan="2" rowspan="2" width="124" align="center">
                                                <img src="https://www.thirumagalmatrimonymadurai.in/app/webroot/img/raaasi.png" width={112} height={45} />
                                            </td>
                                            <td><TextareaAutosize
                                                onChange={onChange}
                                                value={r6}
                                                id="r6"
                                                aria-label="minimum height"
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                            </td>
                                            <div></div>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a5}
                                                    id="a5"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td rowspan="2" colspan="2" width="133" align="center">
                                                <img src="https://www.thirumagalmatrimonymadurai.in/app/webroot/img/amsam.png" width={112} height={45} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a6}
                                                    id="a6"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r7}
                                                    id="r7"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r8}
                                                    id="r8"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <div></div>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a7}
                                                    id="a7"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td width="57" height="63" align="center">
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a8}
                                                    id="a8"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r9}
                                                    id="r9"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r10}
                                                    id="r10"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r11}
                                                    id="r11"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={r12}
                                                    id="r12"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <div></div>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a9}
                                                    id="a9"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a10}
                                                    id="a10"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{   
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a11}
                                                    id="a11"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    onChange={onChange}
                                                    value={a12}
                                                    id="a12"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                            
                        <h6 className="form-wizard-title text-semibold">
                            <p>Contact Details</p>
                        </h6>
                        <div className="row register-row">
                            <div className="col-md-6" style={{ paddingRight: '30px' }}>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>முகவரி<span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <TextareaAutosize labelText="Address"
                                            onChange={onChange}
                                            style={{ fontSize:"15px"}}
                                            id="address"
                                            minRows={6}
                                            Cols={40}
                                            value={address}
                                            formControlProps={{
                                                fullWidth: true,
                                                
                                            }} />
                                            {validateError.address && (
                      <span className={classes.textDanger}>
                        {validateError.address}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>புகைப்படம்<span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input className="validate[required]" type="file" onchange={handleFile} id="photopath" value={photopath} name="data[Register][photopath]" accept="images/*" />
                                        {validateError.photopath && (
                      <span className={classes.textDanger}>
                        {validateError.photopath}
                      </span>
                    )}
                                        <p style={{ marginTop: '10px', whiteSpace: 'nowrap' }}><small>Recommended Size: 363*523</small></p>
                                    </div>
                                </div>
                                {/* <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>jathagampic<span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input className="" type="file" onchange={handleFile} id="jathagampic" value={jathagampic} name="data[Register][photopath]" accept="images/*" />
                                        <p style={{ marginTop: '10px', whiteSpace: 'nowrap' }}><small>Recommended Size: 363*523</small></p>
                                    </div>
                                </div> */}
                            </div>


                            <div className="col-md-6" style={{ paddingLeft: '30px' }}>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>நகரம்<span className="reguired">*</span></label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][city]" onChange={onChange} id="city" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={city} onkeypress="javascript:convertThis(event);" />
                                        {validateError.city && (
                      <span className={classes.textDanger}>
                        {validateError.city}
                      </span>
                    )}
                                    </div>
                                </div>
                                <div className="form-group row" style={{ marginTop: '35px' }}>
                                    <div className="col-md-4">
                                        <label>கூடுதல் தொலைபேசி எண்</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][mobile_number]" onChange={onChange} id="mobile_number" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={mobile_number} onkeypress="javascript:convertThis(event);" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label>இமெயில் முகவரி <span className="reguired">*</span> </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" name="data[Register][email_id]" onChange={onChange} id="email_id" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={email_id} onkeypress="javascript:convertThis(event);" />
                                        {validateError.email_id && (
                      <span className={classes.textDanger}>
                        {validateError.email_id}
                      </span>
                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group" style={{ paddingLeft: '20px' }}>
                            <h6 style={{ fontSize: '14px', paddingBottom: '10px', color:"black", fontWeight:"600" }}> உங்களை சுருக்கமாக விவரிக்கவும்! நீங்கள் எப்படிப்பட்ட நபர் போல! </h6>
                            <TextareaAutosize labelText="comment"
                                onChange={onChange}
                                style={{ fontSize:"15px"}}
                                id="comment"
                                minRows={8}
                                Cols={120}
                                value={comment}
                                formControlProps={{
                                    fullWidth: true,
                                }} />
                                {validateError.comment && (
                      <span className={classes.textDanger}>
                        {validateError.comment}
                      </span>
                    )}
                        </div>
                        <div className="form-group" style={{ paddingLeft: '20px', fontSize: '14px' }}>
                            <input type="checkbox" name="data[Register][accept]" defaultValue="ON" defaultChecked style={{ marginRight: '7px' }} />I Accept the <a href="https://www.thirumagalmatrimonymadurai.in/staticpages/terms" target="_blank">term and Conditions</a>
                        </div>
                        <div className>
                            <div className="g-recaptcha" data-sitekey="6LcC4aQUAAAAAKnqHg8grPN1AaevX5HXp1SDNlr_" />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <input className="btn btn-info reg-submit" defaultValue="Submit" type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        </div >
    </div >












    )
}

