// import package
import axios from "axios";

// import lib
import config from "../../lib/config";
import { SET_CURRENT_USER } from "./../../constant";

export const addregister = async (data) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("phone_number", data.phone_number);
    bodyFormData.append("mname", data.mname);
    bodyFormData.append("religion", data.religion);
    bodyFormData.append("subcaste", data.subcaste);
    bodyFormData.append("height", data.height);
    bodyFormData.append("educ", data.educ);
    bodyFormData.append("w_place", data.w_place);
    bodyFormData.append("different", data.different);
    bodyFormData.append("handireason", data.handireason);
    bodyFormData.append("bestgod", data.bestgod);
    bodyFormData.append("date", data.date);
    bodyFormData.append("month", data.month);
    bodyFormData.append("year", data.year);
    bodyFormData.append("sex", data.sex);
    bodyFormData.append("castename", data.castename);
    bodyFormData.append("birth_time", data.birth_time);
    bodyFormData.append("occup", data.occup);
    bodyFormData.append("income", data.income);
    bodyFormData.append("n_marriage", data.n_marriage);
    bodyFormData.append("children", data.children);
    bodyFormData.append("child_detail", data.child_detail);
    bodyFormData.append("colorname", data.colorname);
    bodyFormData.append("maritalname", data.maritalname);
    bodyFormData.append("castenone", data.castenone);

    // family detail
    bodyFormData.append("father_name", data.father_name);
    bodyFormData.append("mother_name", data.mother_name);
    bodyFormData.append("dad_work", data.dad_work);
    bodyFormData.append("mom_work", data.mom_work);
    bodyFormData.append("brother", data.brother);
    bodyFormData.append("m_brother", data.m_brother);
    bodyFormData.append("sister", data.sister);
    bodyFormData.append("m_sister", data.m_sister);
    bodyFormData.append("regis", data.regis);
    bodyFormData.append("puriveaam", data.puriveaam);
    bodyFormData.append("livesity", data.livesity);
    bodyFormData.append("dever", data.dever);
    bodyFormData.append("peroparty", data.peroparty);
    bodyFormData.append("pob", data.pob);
    bodyFormData.append("r1", data.r1);
    bodyFormData.append("r2", data.r2);
    bodyFormData.append("r3", data.r3);
    bodyFormData.append("r4", data.r4);
    bodyFormData.append("r5", data.r5);
    bodyFormData.append("r6", data.r6);
    bodyFormData.append("r7", data.r7);
    bodyFormData.append("r8", data.r8);
    bodyFormData.append("r9", data.r9);
    bodyFormData.append("r10", data.r10);
    bodyFormData.append("r11", data.r11);
    bodyFormData.append("r12", data.r12);
    bodyFormData.append("a1", data.a1);
    bodyFormData.append("a2", data.a2);
    bodyFormData.append("a3", data.a3);
    bodyFormData.append("a4", data.a4);
    bodyFormData.append("a5", data.a5);
    bodyFormData.append("a6", data.a6);
    bodyFormData.append("a7", data.a7);
    bodyFormData.append("a8", data.a8);
    bodyFormData.append("a9", data.a9);
    bodyFormData.append("a10", data.a10);
    bodyFormData.append("a11", data.a11);
    bodyFormData.append("a12", data.a12);

    //  Horscope Details
    bodyFormData.append("starname", data.starname);
    bodyFormData.append("patham", data.patham);
    bodyFormData.append("zodiacname", data.zodiacname);
    bodyFormData.append("gothra", data.gothra);
    bodyFormData.append("directionname", data.directionname);
    bodyFormData.append("s_year", data.s_year);
    bodyFormData.append("s_month", data.s_month);
    bodyFormData.append("s_day", data.s_day);

    bodyFormData.append("dhosamname", data.dhosamname);


    //contact details
    bodyFormData.append("address", data.address);
    bodyFormData.append("city", data.city);
    bodyFormData.append("photoshow", data.photoshow);
    bodyFormData.append("Photofile", data.photopath);
    bodyFormData.append("Jathagampic", data.jathgampic);
    bodyFormData.append("mobile_number", data.mobile_number);
    bodyFormData.append("email_id", data.email_id);
    bodyFormData.append("comment", data.comment);
    bodyFormData.append("age", data.age);
    
    // console.log("place", bodyFormData);
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/addregister`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.user_token,
      },
      data: bodyFormData,
    }); 
    // console.log("place", bodyFormData);
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const addenquiry = async (data) => {
  try {
    
    // console.log("gddgqwd", data)
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/addenquiry`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
      data: data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateregister = async (data) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("phone_number", data.phone_number);
    bodyFormData.append("mname", data.mname);
    bodyFormData.append("religion", data.religion);
    bodyFormData.append("subcaste", data.subcaste);
    bodyFormData.append("height", data.height);
    bodyFormData.append("educ", data.educ);
    bodyFormData.append("w_place", data.w_place);
    bodyFormData.append("different", data.different);
    bodyFormData.append("handireason", data.handireason);
    bodyFormData.append("bestgod", data.bestgod);
    bodyFormData.append("date", data.date);
    bodyFormData.append("month", data.month);
    bodyFormData.append("year", data.respDatayear);
    bodyFormData.append("sex", data.sex);
    bodyFormData.append("castename", data.castename);
    bodyFormData.append("birth_time", data.birth_time);
    bodyFormData.append("occup", data.occup);
    bodyFormData.append("income", data.income);
    bodyFormData.append("children", data.children);
    bodyFormData.append("child_detail", data.child_detail);
    bodyFormData.append("colorname", data.colorname);
    bodyFormData.append("maritalname", data.maritalname);
    bodyFormData.append("n_marriage", data.n_marriage);
    bodyFormData.append("castenone", data.castenone);

    // family detail
    bodyFormData.append("father_name", data.father_name);
    bodyFormData.append("mother_name", data.mother_name);
    bodyFormData.append("dad_work", data.dad_work);
    bodyFormData.append("mom_work", data.mom_work);
    bodyFormData.append("brother", data.brother);
    bodyFormData.append("m_brother", data.m_brother);
    bodyFormData.append("sister", data.sister);
    bodyFormData.append("m_sister", data.m_sister);
    bodyFormData.append("regis", data.regis);
    bodyFormData.append("puriveaam", data.puriveaam);
    bodyFormData.append("livesity", data.livesity);
    bodyFormData.append("dever", data.dever);
    bodyFormData.append("peroparty", data.peroparty);
    bodyFormData.append("pob", data.pob);
    bodyFormData.append("r1", data.r1);
    bodyFormData.append("r2", data.r2);
    bodyFormData.append("r3", data.r3);
    bodyFormData.append("r4", data.r4);
    bodyFormData.append("r5", data.r5);
    bodyFormData.append("r6", data.r6);
    bodyFormData.append("r7", data.r7);
    bodyFormData.append("r8", data.r8);
    bodyFormData.append("r9", data.r9);
    bodyFormData.append("r10", data.r10);
    bodyFormData.append("r11", data.r11);
    bodyFormData.append("r12", data.r12);
    bodyFormData.append("a1", data.a1);
    bodyFormData.append("a2", data.a2);
    bodyFormData.append("a3", data.a3);
    bodyFormData.append("a4", data.a4);
    bodyFormData.append("a5", data.a5);
    bodyFormData.append("a6", data.a6);
    bodyFormData.append("a7", data.a7);
    bodyFormData.append("a8", data.a8);
    bodyFormData.append("a9", data.a9);
    bodyFormData.append("a10", data.a10);
    bodyFormData.append("a11", data.a11);
    bodyFormData.append("a12", data.a12);

    //  Horscope Details
    bodyFormData.append("starname", data.starname);
    bodyFormData.append("patham", data.patham);
    bodyFormData.append("zodiacname", data.zodiacname);
    bodyFormData.append("gothra", data.gothra);
    bodyFormData.append("directionname", data.directionname);
    bodyFormData.append("s_year", data.s_year);
    bodyFormData.append("s_month", data.s_month);
    bodyFormData.append("s_day", data.s_day);
    //contact details
    bodyFormData.append("address", data.address);
    bodyFormData.append("city", data.city);
    bodyFormData.append("photoshow", data.photoshow);
    bodyFormData.append("Photofile", data.photopath);
    bodyFormData.append("Jathagampic", data.jathgampic);
    bodyFormData.append("mobile_number", data.mobile_number);
    bodyFormData.append("email_id", data.email_id);
    bodyFormData.append("comment", data.comment);
    bodyFormData.append("registerId", data.registerId);
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/updateregister`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.user_token,
      },
      data: bodyFormData,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
export const logout = (history) => {
  localStorage.removeItem("user_token");
  window.location = "/";
};

export const login = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/login`,
      data,
    });

    localStorage.setItem("user_token", respData.data.token);
    localStorage.setItem("user_id", respData.data.result._id);
    localStorage.setItem("name", respData.data.result.name);
    localStorage.setItem("phone_number", respData.data.result.phone_number);
    localStorage.setItem("packagedetail", respData.data.membership.package);
    localStorage.setItem("totalcount", respData.data.membership.totalcount);
    localStorage.setItem("viewedcount", respData.data.membership.viewedcount);
    localStorage.setItem("remainingcount", respData.data.membership.remainingcount);

    return {
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    
    // console.log(err);
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getreligionlist = async (filterData, dispatch) => {
  
  // console.log("yes",);
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getreligionlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdhosamlist = async (filterData, dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getdhosamlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getmaritallist = async (filterData, dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getmaritallist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getcastelist = async (data, filterData, dispatch) => {
  
  // console.log("veram",);
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getcastelist/` + data,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getregislist = async (filterData, dispatch) => {
  
  // console.log("yes",);
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getregislist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getcolorlist = async (filterData, dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getcolorlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getdistrictslist = async (filterData, dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getdistrictslist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getstarlist = async (filterData, dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getstarlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getzodiaclist = async (filterData, dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getzodiaclist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdirectionlist = async (filterData, dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      url: `${config.API}/web/getdirectionlist`,
      params: filterData,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getpayment = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getpayment/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getaboutus = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getaboutus/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getmember = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getmember/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getbank = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getbank/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getprivacy = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getprivacy/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getterms = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getterms/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getregister = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getregisterhome/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    
    // console.log(respData.data, "hdgsjhsgdfhdgfsjhg");
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getregisterlist = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getregisterlist/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    
    // console.log(respData.data, "hdgsjhsgdfhdgfsjhg");
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getviewedprofiles = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getviewedlist/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getanotherviewedprofiles = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getanotherviewedlist/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });

    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};




export const getsavedprofiles = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getwishlist/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};




export const getmyprofile = async (dispatch) => {
  
  // console.log(localStorage, "gsgfhjfgsdhjgdjhs");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getregister/` + localStorage.user_id,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};





export const getservice = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getservice/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getcaste = async (dispatch) => {

  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getcastescount/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getpackage = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getpackage/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getenquiry = async (id, dispatch) => {
  //
  // console.log('yes')
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getenquiry/` + id,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getviewprofile = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getviewprofile/` + id,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getsliderslist = async (dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getsliderslist`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getsitedata = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getsitedata`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
      totalCount:respData.data.totalCount
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getregisterfilter = async (filterData, dispatch) => {
  
  // console.log(filterData, "sdgdf");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getregisterfilter`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
      params: filterData
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
      Femaleount: respData.data.Femaleount,
      Malecount: respData.data.Malecount,
      totalCount:respData.data.totalCount
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getregistercastenone = async (filterData, dispatch) => {
  
  // console.log(filterData, "sdgdf");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getregisterfilter?` + filterData,
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getregisterfilteru = async (dispatch) => {

  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getregisterfilter`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const createorder = async (amount, dispatch) => {
  
  console.log("yes",amount);
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/createOrder`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
      data: {
        amount: Number(amount),
        currency: "INR"
      }
    });
    return {
      loading: false,
      userValue: respData.data.values,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const verifyPayment = async (data, dispatch) => {
  
  // console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/verifyPayment`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
      data: data
    });
    return {
      loading: false,
      userValue: respData.data.validSignature,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const updatecount = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/updatecount/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
      data: {
        viewed_id: id
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getloginviewprofile = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/getregisterlogin/` + id,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const userpackagedetail = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/userpackagedetail/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      success: respData.data.success,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      success: false,
      error: err.response.data.errors,
    };
  }
};



export const deletewishlist = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API}/web/deletewishlist/` + id,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
    });
    return {
      loading: false,
      userValue: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const savewishlists = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/createwishlist/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
      data: {
        wishlist_id: id
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const sendphotorequest = async (id, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API}/web/sendphotorequest/`,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        Authorization: localStorage.user_token,
      },
      data: {
        requested: id
      },
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};