import React, { useState, useEffect } from "react";
import { getaboutus } from '../action/user';
import config from "../../lib/config";
import AOS from 'aos';

function About() {
    const [aboutus_image , setimage] = useState()
    const [aboutus_title , settitle] = useState()
const [aboutus_content, setcontent] = useState()
    const getaboutData = async () => {
        var test = await getaboutus();
        // console.log("image",test);

     let image = config.API+"/images/user/" + test.userValue.image;
         setimage(image);

         settitle(test.userValue.title);
         setcontent(test.userValue.content);
      };
    
      useEffect(() => {
        //logout(history)
        getaboutData();
        window.scrollTo({ top: 550, left: 0, behavior: 'smooth' });
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
      }, []);
    return (
        <div>
        <section className="inner-page-banners">
            <img className="images img-responsive" src={ require('../../assests/images/87295banner.png') }/>
            <div className="clearfix" />
        </section>


        {/* <section style={{ boxShadow: '0px 3px 23px 0px rgb(244, 246, 249)' }}>
            <div className="container about-us">
                <div className="text-center col-md-offset-4 col-md-3">
                    <div id="triangle_left" />
                    <p> எங்களை பற்றி</p>
                    <div id="triangle_right" />
                </div>
            </div>
        </section> */}
            
               <section className="headingzz">
                <h3> எங்களை பற்றி </h3>
                </section>

        {/*//about heading*/}
        {/*details*/}
        <section className="aboutdetails" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="text-center aboutdetail">
                        <h2>ஸ்ரீமல்லிகை திருமண தகவல் மையம்</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {/* <img src={ aboutus_image }/> */}

                        <img src={require("../../assests/images/about-marriage.jpg")}/>
                    </div>
                    <div className="col-md-6 content">
                    <div dangerouslySetInnerHTML={{ __html: aboutus_content }} />
                    
                    </div>
                </div>
            </div>
        </section>
        {/*//details*/}
        {/*about details*/}
        <section className="aboutsection" data-aos="fade-zoom-in">
            <div className="container">
                <div className="about">
                    <h2>குறிப்பு</h2>
                    <p> எங்களிடம் பதிவு செய்த வரன்களுக்கு திருமணம் முடித்தவுடன் எங்களுக்கு தகவல் தெரிவிக்கும்மாறு உங்களை அன்புடன் வேண்டிக்கொள்கிறோம். திருமணம் முடிந்ததை தெரிவிக்காமல் இருந்தால் எங்களது ஸ்ரீ மல்லிகை திருமண தகவல் மையத்தில் ஜாதகம் நீக்கப்படாமல் அப்படியே இருக்கிறது. இதில் வரன் தேடும் நபர்களுக்கு காலவிரயமும், மன உளைச்சலும் எற்படுகிறது. எனவே பதிவு செய்த நீங்கள் திருமணம் முடிந்த விபரத்தை தகவல் தெரிவித்தால் ஜாதகத்தை நீக்கி விடுவோம்.</p>
                </div>
            </div>
        </section>
    </div>
     
    )
}
export default About;
