import OTPInput, { ResendOTP } from "otp-input-react";
import React, { useState } from "react";
import './OTP.css'

function OTP() {
  const [OTP, setOTP] = useState("");
  return (
    <div className="container">
    <div className="HeadOtp">
    <div className="OTP"> 
      <OTPInput className='OtpInput' value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure styles={{border:'none'}}/>
      <button className="reSend" onResendClick={() => console.log("Resend clicked")} >ResendOTP</button>
      <button className="buttonVerify">Verify</button>
    </div>
    </div>
    </div>
  );
}
export default OTP;