import React from 'react';
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactDOM from 'react-dom';


import './index.css';
import App from './App';
// import Sidebar from './components/sidebar/sidebar'
// import Side from './components/sidebar/side'

const queryClient = new QueryClient();
ReactDOM.render(  <QueryClientProvider client={queryClient}>
    <App />
    {/* <ReactQueryDevtools /> */}
  </QueryClientProvider>,document.getElementById('root'));
