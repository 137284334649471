import { MenuItems } from './MenuItems'
import './Navbar.css'
// import * as React from 'react';
// import { Button } from '../Button';
// import { useState } from 'react'
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './Navbar.css';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tabp from '../../ui/tabpanelfree'
// import { IoClose } from 'react-icons/io';
import CancelIcon from '@mui/icons-material/Cancel';

import { getregister } from "./../action/user";
import { getcaste } from "./../action/user";
const style = {

  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #ff6ed',
  boxShadow: 24,
  p: 4,
  borderRadius: '25px'

};

function Navbar() {
  const [openspinner, setOpenspinner] = useState(true);
  const [registers, setRegisters] = useState([])
  
  const getUserData = async () => {
    var test = await getregister();
    setRegisters(test.userValue);
    // console.log(test, "twinkle");
  }

  useEffect(() => {
    //logout(history)
    getUserData();
  }, []);

  const [castes, setCastes] = useState([])
  const getPacksData = async () => {
    var test = await getcaste();
    setCastes(test.userValue);
    // console.log(test, "twinkle");
    // alert(openspinner);
    setInterval(setOpenspinner(!openspinner), 200);

  }

  useEffect(() => {
    //logout(history)
    // setOpenspinner(!openspinner);
    getPacksData();
  }, []);

  const [click, setClick] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);


  return (
    <div>
      <div className="navigation">


        <nav className="NavbarItems" >

          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'bi bi-x' : 'bi bi-list'}></i>
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            {MenuItems.map((item, index) => {
              if (item.title != "Free Search") {
                return (
                  <li key={index} className='nav-item'>
                    <Link className={item.cName} to={item.url} onClick={closeMobileMenu}>
                      {item.title}
                    </Link>

                    {/* <Button onClick={handleOpen}>login</Button>
                            <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style} >
                        
                        <Tabp />

                      </Box>
                    </Modal> */}
                  </li>

                )
              } else {
                return (
                  <li key={index} className='nav-item'>
                    {/* onClick={handleOpen} */}
                    <Button className="nav-link" style={{ color: 'white', fontSize: '17px', fontWeight: "400", marginTop: "6px", textTransform: "none" }} onClick={handleOpen} >{item.title}</Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      {/* <p>guyguygugugguygu</p> */}
                     
                      <Box sx={style} >
                      {/* onClick={handleClose} */}
                        <Button className='timee' style={{ position: "absolute", top: "-24px", right: "-40px", color: "white" }}  onClick={handleClose} variant="primary"> <CancelIcon className='rasathi'/> </Button>
                        <Tabp style={{ marginTop:"10px" }} />
                       
                      </Box>
                     
                    
                    </Modal>
                   
                  </li>
                )
              }
            })}

          </ul>
          {/* <Button>Sign Up</Button> */}
        </nav>

        <div className="Marq">

          <marquee class="que">
            {castes.map(post => (
              <a style={{ padding: '20px', fontWeight: '800', color: 'white' }} href={"/freesearch?castename=" + post._id}>{post.castename} <span>{"(" + " " + post.registerscount + " " + ")"}</span> </a>
            ))}
          </marquee>

        </div>

      </div>
      {/* <Backdrop
        sx={{ color: "#e9018b", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openspinner}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </div>

  );
}

export default Navbar;
