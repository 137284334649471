
import React, { useEffect, useState } from 'react';
import './spiderman.css'
import FavoriteIcon from '@mui/icons-material/Favorite';
// import logo from '../../assests/images/profile.jpg'

// import { getregister, getregisterfilter , getcaste } from "./../action/user";
import config from "../../lib/config";
import { getmyprofile } from "./../action/user";
import ImageTab from '../sidebar/ImageTabSlider';
import ImageGallery from "react-image-gallery";

function Freesearch() {

    const [showPopup, setShowPopup] = useState(false);

    const [post, setRegister] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [height1, setHeight] = useState(window.innerHeight);
    const [profileimg, setprofileimg] = useState([]);
    const [profileimg1, setprofileimg1] = useState([]);
    // const [registerfilter, setRegisterfilters]=useState([]);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {

        // console.log(width);

        // console.log(height1);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    });

    const togglePopup = () => {
        // Pop up 의 open/close 상태에 따라

        setShowPopup(!showPopup);
    };

    const closeModal = (e) => {

        // console.log(e.target.className);
        if (e.target.className === "popup") {
            togglePopup();
        }
    };

    const getUserData = async () => {
        var test = await getmyprofile();

        console.log(test, "Checkkkkkkkkkkkkkkkkkkkkkkkkk");
        setRegister(test.userValue);
        // setprofileimg1(test.userValue.userValue.photopath[0])
        // console.log(test.userValue.userValue.photopath[0], "profileimg1profileimg1profileimg1")
        if (test.userValue.photopath.length && test.userValue.photopath[0]) {
            setprofileimg(test.userValue.photopath.map(url => ({
                original: config.API + `/images/user/${url}`,
                thumbnail: config.API + `/images/user/${url}`,
                originalHeight: "200",
                originalWidth: "200",
                thumbnailHeight: "50",
            })))
        }
        // console.log(test, "twinkle");
    }

    function changenumbertojathagam(text) {
        if (text) {
            const tamil_lang = [
                'வி',
                'கே',
                'ல',
                'செ',
                'பு',
                'ச',
                'ரா',
                'சனி',
                'சூ',
                'சு',
                'மா',
                'வ'
            ];
            const newarray = text.split("/");
            const final = newarray.map(e => {
                return e = tamil_lang[e - 1];
            });
            //    
            // console.log(final.join("/"), "bdfgfdgdf");
            return final.join("/");
        } else {
            return "";
        }
    }
    useEffect(() => {
        //logout(history)
        getUserData();
    }, []);


    // const getUserData = async () => {
    //     // 
    // console.log(filterobj,"fghjfghjfgdjhg");post
    //   var test =  await getregisterfilter();
    //   setRegisterfilters(test.userValue);
    //   
    // console.log(test, "win");

    //   }
    //   useEffect(() => {
    //     //logout(history)
    //     getUserData();
    //   }, []);



    return (

        <div className="container">


            <div className="textCenter" >
             { post.membership?  <button className='theri'>{post.membership} Package</button>:null}
                <div className="App">
                    {/* <a href='/edit'> */}
                    <button className='kuselan' onClick={togglePopup}>Edit Profile</button>
                    {showPopup ? (
                        <div className="popup" onClick={(e) => closeModal(e)}>
                            <div className="popup_inner">
                                <h2 style={{ color: "black", fontWeight: "bold" }}>You can change your details, contact your admin.</h2>
                                <h2 style={{ color: "black", fontWeight: "bold", marginTop: "25px" }}>Call: 99 43 31 53 31 </h2>
                                <button className="close" onClick={togglePopup}>
                                    Close
                                </button>
                            </div>
                        </div>
                    ) : null}
                    {/* </a> */}
                </div>
                <div className='container'>
                    <div className='row'>
                        <p className='valimai'>{post.mname}<span className='spank'> ( {post.userid} )</span></p>

                    </div>

                    <div className="row" style={width <= 774 ? { height: 'auto' } : { height: '340px' }}>

                        <div className="col-md-12 sm-12 ">


                            <div className="row " >
                                <div className="col-md-6 col-lg-4" >
                                    {profileimg.length ?
                                        // <ImageGallery items={profileimg} showFullscreenButton={false} />
                                        <img className="logo viewphotoz" style={{ marginTop: "15px" }} src={config.API + "/images/user/" + post.photopath[0]} />
                                        : <img src={"sex" in post && post.sex == "Male" ? require('../../assests/images/man.png') : require('../../assests/images/woman.png')} style={{ width: '70%' }} />

                                    }
                                    {/* <ImageTab/> */}

                                </div>

                                <div className="col-md-6 col-lg-4 karkka">
                                    <table className='AnDroid' >

                                        <tbody><tr >
                                            <td className='billa' >பெயர்</td>
                                            <td className='kuruvi' >:</td>
                                            <td className='alan' >{post.mname} </td>
                                        </tr>
                                            <tr>
                                                <td className='billa'>கல்வித்தகுதி</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.educ}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>திருமண நிலை</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.maritalname == "Marriage" ? "முதல்மணம்" : "மறுமணம்"}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>உட்பிரிவு</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.subcaste}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>இனம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.castename}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>வயது </td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.age = new Date().getFullYear() - post.year + "" + "Yrs"}</td>
                                            </tr>
                                        </tbody></table>
                                </div>

                                <div className="col-md-6 col-lg-4 karkka">
                                    <table className="sensay" >
                                        <tbody><tr>
                                            <td className='billa'>பிறந்த தேதி</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.date + "/" + post.month + "/" + post.year}</td>
                                        </tr>
                                            <tr>
                                                <td className='billa'>உயரம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.height}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>நிறம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.colorname}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>வேலை/தொழில்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.occup}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>சம்பள வருமானம</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.income}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>மாவட்டம் </td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'> {post.city}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* <button href="#" className='burg'><b>புகைப்படம் பார்க்க ?</b></button>     */}
                                    {/* <button href="#" className='burg'>Save<FavoriteIcon style={{height:'19px', width:'19px', marginLeft:'5px', marginBottom:'2px'}}/></button> */}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="centerFruit" >
                <div className='container'>

                    <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>

                        <div className="col-md-12 sm-12">

                            <p className='name1'>குடும்ப விவரங்கள்</p>

                            <div className="row " >

                                <div className="col-md-6 ">

                                    <table className='Boomer' >


                                        <tbody><tr>
                                            <td className='billa1'>தந்தையின் பெயர்</td>
                                            <td className='kuruvi1'>:</td>
                                            <td className='alan1'>{post.father_name}</td>
                                        </tr>
                                            <tr>
                                                <td className='billa1'>தாய் பெயர்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.mother_name}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>தந்தையின் வேலை</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.dad_work}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>தாயின் வேலை</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.mom_work}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>சகோதரர்கள்(இவரையும் சேர்த்து)</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'> {post.brother}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>திருமணமான சகோதரர்கள்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'> {post.m_brother}</td>
                                            </tr>

                                        </tbody></table>
                                </div>
                                <div className="col-md-6 karkka">
                                    <table className='Boomer' >
                                        <tbody>
                                            <tr>
                                                <td className='billa1'>சகோதரிகள்(இவரையும் சேர்த்து)</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.sister}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>திருமணமான சகோதரிகள்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.m_sister}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>பதிவுசெய்பவர்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.regis}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>பூர்வீகம்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.puriveaam}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>சொத்துக்கள்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'> {post.livesity}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>சீர் வருசை</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'> {post.dever} </td>
                                            </tr>


                                        </tbody>
                                    </table>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>





















            <div className="centerFruit" >

                <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>
                    <div className="col-md-12 sm-12 ">

                        <p className='name1'>முகவரி விவரங்கள்</p>

                        <div className="row " >

                            <div className="col-md-12 ">

                                <table className='Boomer' >


                                    <tbody><tr>
                                        <td className='billa'>கைபேசி எண்</td>
                                        <td className='kuruvi'>:</td>
                                        <td className='alan'>{post.phone_number}
                                            {/* { (localStorage.user_token) ? (<><a href='/myprofile' style={{ color:"white", fontSize:"18px", borderRadius:"6px", backgroundColor:"#b91d97", padding:"7px 9px 12px 9px"}}>My profile</a><a   onClick={onlogout} style={{textTransform:'none', cursor:"pointer", color:"#b91d97", fontSize:"22px", marginLeft:"9px", fontWeight:"800"}}><img style={{ marginTop:"-7px", marginLeft:"8px", maxWidth:"24px"}} src={ require('../../assests/images/logout.png') } /> Logout</a></>) : (<><a href='/registration' style={{ color:"white", fontSize:"18px", borderRadius:"6px", backgroundColor:"#b91d97", padding:"7px 9px 12px 9px", marginLeft:"8px"}}>Registration</a> <a   onClick={handleOpen} style={{textTransform:'none', cursor:"pointer", color:"#b91d97", fontSize:"22px", marginLeft:"9px", fontWeight:"700"}}> <img style={{ marginTop:"-7px", marginLeft:"8px", maxWidth:"18px"}} src={ require('../../assests/images/person.png') } /> Login</a></>)} */}
                                        </td>
                                    </tr>
                                        <tr>
                                            <td className='billa'>முகவரி</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.address}</td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>இமெயில் முகவரி</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.email_id}</td>
                                        </tr>

                                    </tbody></table>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="centerFruit" >

                <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>
                    <div className="col-md-12 sm-12 ">

                        <p className='name1'>ஜாதக விவரங்கள்</p>

                        <div className="row " >

                            <div className="col-md-6 ">

                                <table className='Boomer' >


                                    <tbody><tr>
                                        <td className='billa'>நட்சத்திரம்</td>
                                        <td className='kuruvi'>:</td>
                                        <td className='alan'>{post.starname}</td>
                                    </tr>
                                        <tr>
                                            <td className='billa'>ராசி</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.zodiacname}</td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>லக்னம்</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.gothra}</td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>திசை இருப்பு</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.directionname}</td>
                                        </tr>


                                    </tbody></table>
                            </div>
                            <div className="col-md-6 karkka">
                                <table className='Boomer' >
                                    <tbody>
                                        <tr>
                                            <td className='billa'>வருடம்</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.s_year}</td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>மாதம்</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'> {post.s_month}</td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>நாள்</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.s_day}</td>
                                        </tr>
                                        <tr>
                                            <td className='billa'>பாதம்</td>
                                            <td className='kuruvi'>:</td>
                                            <td className='alan'>{post.patham}</td>
                                        </tr>
                                        {/* <tr>
                                    <td className='billa'>தங்களை பற்றி</td>
                                    <td className='kuruvi'>:</td>
                                    <td className='alan'>{post.comment}</td>
                                </tr> */}

                                    </tbody>
                                </table>


                            </div>


                        </div>
                    </div>
                </div>
            </div>



            <div className style={{ marginTop: '30px' }}>
                <img src={require('../../assests/images/chat_new.png')} className="img-responsive" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                <div className="form-group table-responsive" style={{ fontSize: "17px" }}>
                    <table className="rassi-table" style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r1)}
                                        id="r1"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r2)}
                                        id="r2"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r3)}
                                        id="r3"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r4)}
                                        id="r4"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <div></div>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a1)}
                                        id="a1"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a2)}
                                        id="a2"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a3)}
                                        id="a3"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a4)}
                                        id="a4"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r5)}
                                        id="r5"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td colspan="2" rowspan="2" width="124" align="center">
                                    <img src={require('../../assests/images/raaasi.png')} width={112} height={45} />
                                </td>
                                <td><textarea
                                    value={changenumbertojathagam(post.r6)}
                                    id="r6"
                                    readonly
                                    style={{ textAlign: "center" }}
                                    minRows={3}
                                    Cols={8}
                                    formControlProps={{
                                        fullWidth: true,
                                    }} />
                                </td>
                                <div></div>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a5)}
                                        id="a5"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td rowspan="2" colspan="2" width="133" align="center">
                                    <img src={require('../../assests/images/amsam.png')} width={112} height={45} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a6)}
                                        id="a6"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r7)}
                                        id="r7"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r8)}
                                        id="r8"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <div></div>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a7)}
                                        id="a7"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td width="57" height="63" align="center">
                                    <textarea
                                        value={changenumbertojathagam(post.a8)}
                                        id="a8"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r9)}
                                        id="r9"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r10)}
                                        id="r10"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r11)}
                                        id="r11"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.r12)}
                                        id="r12"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <div></div>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a9)}
                                        id="a9"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a10)}
                                        id="a10"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a11)}
                                        id="a11"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                                <td>
                                    <textarea
                                        value={changenumbertojathagam(post.a12)}
                                        id="a12"
                                        readonly
                                        style={{ textAlign: "center" }}
                                        minRows={3}
                                        Cols={8}
                                        formControlProps={{
                                            fullWidth: true,
                                        }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>





        </div>











    )
}

export default Freesearch;