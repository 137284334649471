import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { getregister, getbank, getdhosamlist } from "./../action/user";
import { getpackage } from "./../action/user";
import { getcaste, getcastescount } from "./../action/user";
import { getsliderslist } from "./../action/user";
import ReactPlayer from "react-player";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import config from "../../lib/config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import AOS from "aos";

import {
  addregister,
  getreligionlist,
  getregislist,
  getcastelist,
  getmaritallist,
  login,
  setCurrentUser,
  getcolorlist,
  getstarlist,
  getzodiaclist,
  getdirectionlist,
  getdistrictslist,
} from "../action/user";


const useStyles = makeStyles((theme) => ({
  InputLabel: {
    color: "rgb(0,0,0)",
    fontFamily: "Source Sans Pro",
  },
}));

function Homepage() {
  const classes = useStyles();
  const [registers, setRegisters] = useState([]);
  const [religions, setreligion] = useState([]);
  const [districts, setdistricts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [openspinner, setOpenspinner] = useState(false);
  const [castenone, setcastenone] = useState(false);
  const [castes, setCastes] = useState([]);
  const [caste, setCaste] = useState([]);

  const [stars, setstar] = useState([]);
  const [zodiacs, setzodiac] = useState([]);
  const [dhosams, setdhosam] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [banks, setBanks] = useState([]);
  const [idpass, setidpass] = useState("61fe0d29b9938726b92dcb44");


  console.log("registers",registers);
  const initialFormValue = {
    religion: "61fe0d29b9938726b92dcb44",
    subcaste: "",
    sex: "",
    castename: "",
    maritalname: "",
    date: "",
    month: "",
    year: "",
    age1: "",
    age2: "",
    userid: "",
    city: "",
    zodiacname: "",
    starname: "",
    dhosamname: "",
    m_sister: "",
    m_brother: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);

  const onChange = async (e) => {
    e.preventDefault();
    // var testAbdul = convertThis(event,numchar);
    console.log(e.target);
    const { id, value } = e.target;
    // console.log(value);
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    // console.log(formValue)
    //setValidateError(formData)
    console.log("Testgal", formData);
    var test = await getcastelist(formData.religion);

    const castes = await test.userValue.map((d) => ({
      title: d.castename,
      value: d._id,
    }));
    setCastes(castes);
  };

  const {
    religion,
    subcaste,
    date,
    month,
    year,
    sex,
    castename,
    maritalname,
    age1,
    age2,
    userid,
    city,
    zodiacname,
    starname,
    dhosamname,
    m_brother,
    m_sister,
  } = formValue;
  const [click, setclick] = useState("");
  const sexch = [
    { title: "ஆண்", value: "Male" },
    { title: "பெண்", value: "Female" },
  ];
  const handlechangecastenone = () => {
    if (click == "") {
      setclick("Yes");
    } else {
      setclick("");
    }
  };
  const ages1 = [
    { title: "18", value: "18" },
    { title: "19", value: "19" },
    { title: "20", value: "20" },
    { title: "21", value: "21" },
    { title: "22", value: "22" },
    { title: "23", value: "23" },
    { title: "24", value: "24" },
    { title: "25", value: "25" },
    { title: "26", value: "26" },
    { title: "27", value: "27" },
    { title: "28", value: "28" },
    { title: "29", value: "29" },
    { title: "30", value: "30" },
    { title: "31", value: "31" },
    { title: "32", value: "32" },
    { title: "33", value: "33" },
    { title: "34", value: "34" },
    { title: "35", value: "35" },
    { title: "36", value: "36" },
    { title: "37", value: "37" },
    { title: "38", value: "38" },
    { title: "39", value: "39" },
    { title: "40", value: "40" },
    { title: "41", value: "41" },
    { title: "42", value: "42" },
    { title: "43", value: "43" },
    { title: "44", value: "44" },
    { title: "45", value: "45" },
    { title: "46", value: "46" },
    { title: "47", value: "47" },
    { title: "48", value: "48" },
    { title: "49", value: "49" },
    { title: "50", value: "50" },
  ];

  const ages2 = [
    { title: "19", value: "19" },
    { title: "20", value: "20" },
    { title: "21", value: "21" },
    { title: "22", value: "22" },
    { title: "23", value: "23" },
    { title: "24", value: "24" },
    { title: "25", value: "25" },
    { title: "26", value: "26" },
    { title: "27", value: "27" },
    { title: "28", value: "28" },
    { title: "29", value: "29" },
    { title: "30", value: "30" },
    { title: "31", value: "31" },
    { title: "32", value: "32" },
    { title: "33", value: "33" },
    { title: "34", value: "34" },
    { title: "35", value: "35" },
    { title: "36", value: "36" },
    { title: "37", value: "37" },
    { title: "38", value: "38" },
    { title: "39", value: "39" },
    { title: "40", value: "40" },
    { title: "41", value: "41" },
    { title: "42", value: "42" },
    { title: "43", value: "43" },
    { title: "44", value: "44" },
    { title: "45", value: "45" },
    { title: "46", value: "46" },
    { title: "47", value: "47" },
    { title: "48", value: "48" },
    { title: "49", value: "49" },
    { title: "50", value: "50" },
    { title: "51", value: "51" },
  ];

  const mbrothers = [
    { title: "0", value: "0" },
    { title: "1", value: "1" },
    { title: "2", value: "2" },
    { title: "3", value: "3" },
    { title: "4", value: "4" },
    { title: "5", value: "5" },
    { title: "6", value: "6" },
    { title: "7", value: "8" },
    { title: "9", value: "9" },
    { title: "10", value: "10" },
    { title: "11", value: "11" },
    { title: "12", value: "12" },
  ];

  const sisters = [
    { title: "0", value: "0" },
    { title: "1", value: "1" },
    { title: "2", value: "2" },
    { title: "3", value: "3" },
    { title: "4", value: "4" },
    { title: "5", value: "5" },
    { title: "6", value: "6" },
    { title: "7", value: "7" },
    { title: "8", value: "8" },
    { title: "9", value: "9" },
    { title: "10", value: "10" },
    { title: "11", value: "11" },
    { title: "12", value: "12" },
  ];

  const maritals = [
    { title: "முதல் மணம்", value: "Marriage" },
    { title: "மறுமணம்", value: "Remarriage" },
  ];

  const getUserData = async () => {
    var test = await getreligionlist();
    // console.log("fdssfdsfdsf", test);
    const option1 = await test.userValue.map((d) => ({
      title: d.religion,
      value: d._id,
    }));
    setreligion(option1);

    var iddddd = idpass;
    var test = await getcastelist(iddddd);
    // console.log("fdssfdsfdsf", test);
    const castes = await test.userValue.map((d) => ({
      title: d.castename,
      value: d._id,
    }));
    setCastes(castes);

    var test = await getsliderslist();
    setSliders(test.userValue);
    // console.log(test, "twinkle");
    var test = await getpackage();
    setPackages(test.userValue);
    // console.log(test, "twinkle");
    var test = await getregister();
    // console.log(test, "bdfgfdgdf");
    setRegisters(test.userValue);
    var test = await getcaste();
    setCaste(test.userValue);
    // console.log(test, "twinkle");
    var test = await getbank();
    setBanks(test.userValue);
    // console.log(test, "twinkle");
    var test = await getdistrictslist();
    const districts = await test.userValue.map((d) => ({
      title: d.city,
      value: d._id,
    }));
    setdistricts(districts);

    var test = await getdhosamlist();

    // console.log("fdssfdsfdsf", test);
    const dhosamlist = await test.userValue.map((d) => ({
      title: d.dhosamname,
      value: d._id,
    }));
    setdhosam(dhosamlist);

    var test = await getzodiaclist();
    //
    // console.log("fdssfdsfdsf", test);
    const zodiacs = await test.userValue.map((d) => ({
      title: d.zodiacname,
      value: d._id,
    }));
    setzodiac(zodiacs);

    var test = await getstarlist();
    //
    // console.log("fdssfdsfdsf", test);
    const stars = await test.userValue.map((d) => ({
      title: d.starname,
      value: d._id,
    }));
    setstar(stars);
  };

  const handleFormSubmit = async (e) => {
    //console.log("saran");
    e.preventDefault();
    console.log(formValue);
    let reqData = {
      religion,
      subcaste,
      date,
      month,
      year,
      sex,
      castename,
      maritalname,
      age1,
      age2,
      userid,
      city,
    };
    console.log("req", reqData);
    var filterarray =
      "userid=" +
      userid +
      "&religion=" +
      religion +
      "&subcaste=" +
      subcaste +
      "&castename=" +
      castename +
      "&sex=" +
      sex +
      "&maritalname=" +
      maritalname +
      "&age1=" +
      age1 +
      "&age2=" +
      age2 +
      "&city=" +
      city +
      "&castenone=" +
      click +
      "&zodiacname=" +
      zodiacname +
      "&starname=" +
      starname +
      "&dhosamname=" +
      dhosamname +
      "&m_brother=" +
      m_brother +
      "&m_sister=" +
      m_sister;
    window.location = "/freesearch?" + filterarray;
  };

  useEffect(() => {
    //logout(history)
    getUserData();
    // setInterval(()=>setOpenspinner(!openspinner), 3000);
    // or simply just AOS.init();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }, [idpass]);

  return (
    <>
      <div className="container-fluid">
        <div className="nfs">
          <img
            className="bannerzz"
            src={require("../../assests/images/20525home-banner.png")}
          />
        </div>
        {/* <Carousel interval={1000} keyboard={false} pauseOnHover={true}>
          {sliders.map(post => (
            <Carousel.Item >
              <img className="d-block w-100"
                src={config.API + "/images/user/" + post.banner} />
            </Carousel.Item  >
          ))}
        </Carousel> */}
        {/* <Carousel.Item style={{'height':"450px"}}>  
        <img style={{'height':"450px"}}  
          className="d-block w-100"  
           src={require('../../assests/images/20525home-banner.png')}    />  
                </Carousel.Item>  
              <Carousel.Item style={{'height':"450px"}}>  
              <img style={{'height':"450px"}}  
POSTts/images/32930Banner.png')}   />  
                </Carousel.Item>   */}

        <div>
          <section className="under-banner-title">
            <div className="text-center">
              <a href="/plandetail">
                <p>
                  தாங்கள் விரும்பினால் மையத்தின் மூலமாக இருவீட்டாரிடமும் பேசி
                  திருமணத்தை முடித்து தருகிறோம்
                </p>
              </a>
            </div>
          </section>
        </div>

        {/* <section className="ourpackages" style={{ background: '#fafbfd', paddingTop: '30px' }} data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="title">
                <h2 className="text-center">மெம்பர்ஷிப் பிளான் </h2>
              </div>
            </div>
            <div className="selectpackage" id="selectpackage">
              <div className="row">
                {packages.map(post => (
                  <div className="col-md-3 col-sm-6">
                    <div className="package-boxshadow" data-aos="flip-right">
                      <div className="silver">
                        <h2>{post.packagename}</h2>
                        <div className="pricecircle">
                          <h4><img style={{ height: '20px' }} src={require('../../assests/images/rupee.png')} /></h4>
                          <h1 style={{ fontSize: '35px', fontWeight: '800' }}>{post.amount}</h1>
                          <p>மட்டும்</p>
                        </div>
                        <h5>சுயவிவரங்களின் எண்ணிக்கை</h5>
                        <h3>{post.count}</h3>
                        <h4 style={{ marginBottom: '20px', fontWeight: "700", lineHeight: "23px" }}>{post.monthvalidate + " " + "மாதங்களுக்கு செல்லுபடியாகும்"}</h4>
                        <a className="button" href="#bankdetail">SELECT PACKAGE</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w3layouts-footerdesign">
            <div className="footerdesign">
              <img src="{ require('../../assests/images/bg.png') }" alt="" />
            </div>
            <div className="clearfix" />
          </div>
        </section> */}

        <section className="freee">
          <div className="container title">
            <h2 className="custom-text">இலவச தேடல்</h2>

            <div className="freesubmit">
              <form
                className={classes.form}
                noValidate
                onSubmit={handleFormSubmit}
              >
                <div className="row">
                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">SM-ID </label>
                      <input
                        type="text"
                        placeholder="Eg : 12345"
                        className="form-control"
                        onChange={onChange}
                        id="userid"
                        style={{ fontSize: "18px", height: "38px" }}
                        value={userid}
                        name="userid"
                        onkeypress="javascript:convertThis(event);"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">பாலினம் </label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={sex}
                        id="sex"
                        onChange={onChange}
                      >
                        <option value="">Select Gender</option>
                        {sexch.length
                          ? sexch.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">மதம் </label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={religion}
                        id="religion"
                        onChange={onChange}
                      >
                        <option value="">Select Religion</option>
                        {religions.length
                          ? religions.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">ஜாதி </label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={castename}
                        id="castename"
                        onChange={onChange}
                      >
                        <option value="">Select Caste</option>
                        {castes.length
                          ? castes.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">திருமண நிலை</label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={maritalname}
                        id="maritalname"
                        onChange={onChange}
                      >
                        <option value="">திருமண நிலை</option>
                        {maritals.length
                          ? maritals.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">மாவட்டம்</label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={city}
                        id="city"
                        onChange={onChange}
                      >
                        <option value="">Select district</option>
                        {districts.length
                          ? districts.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">வயது </label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={age1}
                        id="age1"
                        onChange={onChange}
                      >
                        <option value="">Select From Age</option>
                        {ages1.length
                          ? ages1.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">வயது</label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={age2}
                        id="age2"
                        onChange={onChange}
                      >
                        <option value="">Select To Age</option>
                        {ages2.length
                          ? ages2.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">நட்சத்திரம்</label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={starname}
                        id="starname"
                        onChange={onChange}
                      >
                        <option value="">Select star</option>
                        {stars.length
                          ? stars.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">ராசி</label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={zodiacname}
                        id="zodiacname"
                        onChange={onChange}
                      >
                        <option value="">Select zodiacs</option>
                        {zodiacs.length
                          ? zodiacs.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">தோஷம்</label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={dhosamname}
                        id="dhosamname"
                        onChange={onChange}
                      >
                        <option value="">Select dosham</option>
                        {dhosams.length
                          ? dhosams.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">சகோதரர்கள்</label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={m_brother}
                        id="m_brother"
                        onChange={onChange}
                      >
                        <option value="">Select brother</option>
                        {mbrothers.length
                          ? mbrothers.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">சகோதரிகள்</label>
                      <select
                        class="form-select drs form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={m_sister}
                        id="m_sister"
                        onChange={onChange}
                      >
                        <option value="">Select sister</option>
                        {sisters.length
                          ? sisters.map((e) => (
                              <option value={e.value}>{e.title}</option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>

                  <div
                    className="col-md-3"
                    style={{ textAlign: "left", marginTop: 15 }}
                  >
                    <label style={{ fontSize: "17px" }}> ஜாதி தடை இல்லை </label>
                    <FormControlLabel
                      style={{
                        color: "white !important",
                        fontSize: "20px",
                        marginLeft: "10px",
                      }}
                      control={
                        <Checkbox
                          onChange={() => handlechangecastenone()}
                          name="castenone"
                          // style={{ color: "white" }}
                        />
                      }
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="submitzz">
                      <button
                        style={{ backgroundColor: "#662e91",
                          padding: "10px 35px 10px 35px",
                          fontSize: "18px",
                          color: "#fff",
                         }}
                        type="submit"
                        name="search"
                        class="btn custom-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section className="userprofile" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="text-center title">
                <h2>புதிய வரன்கள்</h2>
              </div>
            </div>
            <div className="featureprofile owl-carousel owl-theme">
              <div className="item">
                {registers &&
                  registers.map((post) => (
                    <div
                      className="col-md-3 col-sm-6 biseller-column"
                      style={{ overflow: "hidden" }}
                      data-aos="flip-right"
                    >
                      <img
                        src={
                          post.photopath.length && post.photopath[0]
                            ? config.API + "/images/user/" + post.photopath
                            : post.sex == "Male"
                            ? require("../../assests/images/manz.png")
                            : require("../../assests/images/womanz.png")
                        }
                        style={{ width: "100%" }}
                      />
                      <a href={"/batman/" + post._id}>
                        <div className="agile-overlay">
                          <h4>
                            Reg No
                            <span className="colen"> : </span> {post.userid}
                          </h4>
                          <ul>
                            <li className="row">
                              <span className="col-4">பெயர்</span>{" "}
                              <span className="col-1">: </span>{" "}
                              <span className="col-6"> {post.mname} </span>{" "}
                            </li>
                            <li className="row">
                              <span className="col-4">வயது</span>
                              <span className="col-1">: </span>{" "}
                              <span className="col-6">
                                {
                                  (post.age =
                                    new Date().getFullYear() -
                                    post.year +
                                    " Yrs")
                                }
                              </span>{" "}
                            </li>
                            <li className="row">
                              <span className="col-4">உயரம்</span>
                              <span className="col-1">: </span>{" "}
                              <span className="col-6">{post.height}</span>{" "}
                            </li>
                            <li className="row">
                              <span className="col-4">மதம்</span>{" "}
                              <span className="col-1">: </span>
                              <span className="col-6">{post.religion}</span>
                            </li>
                            <li className="row">
                              <span className="col-4">ஜாதி</span>{" "}
                              <span className="col-1">: </span>
                              <span className="col-6">{post.castename}</span>
                            </li>
                            <li className="row">
                              <span className="col-4">ராசி</span>{" "}
                              <span className="col-1">: </span>
                              <span className="col-6">{post.zodiacname}</span>
                            </li>
                            <li className="row">
                              <span className="col-5">நட்சத்திரம்</span>{" "}
                              <span
                                className="col-1"
                                style={{ marginLeft: "6px" }}
                              >
                                :{" "}
                              </span>
                              <span className="col-5">{post.starname}</span>
                            </li>
                            {/* <li className="row"><span className="col-4">பிறந்த தேதி</span> <span className="col-1">: </span><span  className="col-6">{post.date + "-"+ post.month +"-"+ post.year}</span></li> */}
                            {/* <li><span>உட்பிரிவு</span> <span className="colen">: </span>{post.subcaste} </li>
                    <li><span>நட்சத்திரம்</span> <span className="colen">: </span>{post.starname}</li> */}
                          </ul>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>

        <section className="use-full-links">
          <div className="container">
            <div className="row">
              <div className="text-center title">
                <h2 style={{ marginTop: "36px", marginBottom: "30px" }}>
                  வரன்களின் எண்ணிக்கை
                </h2>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {caste.map((post) => (
                <div className="col-md-3" style={{ textAlign: "left" }}>
                  <div className="caste-name" data-aos="flip-up">
                    <p>
                      <span>| </span>
                      <a href={"/freesearch?castename=" + post._id}>
                        {post.castename}{" "}
                        <span>
                          {"(" + " " + post.registerscount + " " + ")"}
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="bankdetail" id="bankdetail">
          <div className="container-fluid">
            <div className="bank">
              <div>
                <h3 className="payonline">PAY ONLINE</h3>
                <div className="row">
                  {/* <div className="col-md-6">
                  
                    <img className="logo" src={require('../../assests/images/gp (1).png')} />
                    <p className="pno">Phone Number :  99 4331 5331</p>
                    <div>
                      <h1>QR Code</h1>
                      <img className="logo qrscan" src={require('../../assests/images/qr (1).jpeg')} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img className="logo" src={require('../../assests/images/phpay (2).png')} />
                    <p className="pno">Phone Number :  99 4331 5331</p>
                    <div>
                      <h1>QR Code</h1>
                      <img className="logo qrscan" src={require('../../assests/images/qr (1).jpeg')} />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="yeah">
                      {/* <img className="logo" src={require('../../assests/images/gp (1).png')} /> */}
                      {/* <img className="logo" src={require('../../assests/images/phpay (2).png')} /> */}
                      <p className="pno">Phone Number : 99 4331 5331</p>
                      <p className="pno">Phone Number : 98 4213 4672</p>
                      <p className="pno">Phone Number : 82 2020 4747</p>
                    </div>
                  </div>
                  <div className="gpay-head">
                    {/* <img
                      src={require("../../assests/images/gp (1).png")}
                      alt="GPay"
                    /> */}
                  </div>

                  {/* <div className="col-md-12 row account">
                    <div className="col-md-6 bankdetails d-flex flex-column justify-content-space-evenly">
                      <h1 className="text-center">நீங்கள் நேரடியாக பணத்தை வங்கியிலும் செலுத்தலாம்.</h1>
                      <h3 className="text-center">Bank Account Details</h3>
                      <div className="row">
                        {banks.length && banks.map(el => (
                          <div className="col-md-6 col-sm-10 mx-auto">
                            <div className="item">
                              <div className="card">
                                <div className="content ">
                                  <p><b>BANK</b><span className="dot">:</span><span className="a1a1">{el.bankname}</span></p>
                                  <p><b>A/C NAME </b><span className="dot">:</span><span className="a1a1"> {el.accountname}</span></p>
                                  <p><b>A/C NO </b><span className="dot">:</span><span className="a1a1"> {el.accountno}</span></p>
                                  <p><b>BRANCH</b><span className="dot">:</span><span className="a1a1" style={{ fontSize: "14px" }}> {el.branchname}</span></p>
                                  <p><b>IFSC CODE</b><span className="dot">:</span><span className="a1a1"> {el.ifsc_no}</span></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                 
                      <img className="logo qrscan" src={require('../../assests/images/malligaipay.jpeg')} alt="QR Code" />
                    </div>
                  </div> */}
                  <div className="account">
                    <div className="account-bg-color">

                    
                    <h1 className="text-center" style={{ marginTop: "20px", marginBottom: "20px" }}>
                      நீங்கள் நேரடியாக பணத்தை வங்கியிலும் செலுத்தலாம்.
                    </h1>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-xl-3">
                      {banks.length && banks.map(el => (
                          // <div className="col-md-6 col-sm-10 mx-auto">
                            <div className="item">
                             
                              <div className="card" style={{  height: "360px",paddingTop:"20px" }}>
                                <div className="content  custome-content" >
                                  <p style={{ fontSize: "18px" }}><b>BANK</b><span className="dot">:</span><span className="a1a1">{el.bankname}</span></p>
                                  {/* <p><b>BANK</b><span className="dot">:</span><span className="a1a1">AXIS BANK</span></p> */}
                                  <p style={{ fontSize: "18px" }}><b>A/C NAME </b><span className="dot">:</span><span className="a1a1"> {el.accountname}</span></p>
                                  <p style={{ fontSize: "18px" }}><b>A/C NO </b><span className="dot">:</span><span className="a1a1"> {el.accountno}</span></p>
                                  <p style={{ fontSize: "18px" }}><b>BRANCH</b><span className="dot">:</span><span className="a1a1" style={{ fontSize: "18px" }}> {el.branchname}</span></p>
                                  <p style={{ fontSize: "18px" }}><b>IFSC CODE</b><span className="dot">:</span><span className="a1a1"> {el.ifsc_no}</span></p>
                                </div>
                              </div>
                            

                            
                            </div>
                          // </div>
                        ))}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-xl-3">
                      {banks.length && banks.map(el => (
                          // <div className="col-md-6 col-sm-10 mx-auto">
                            <div className="item">
                              <div className="card" style={{  height: "360px" ,paddingTop:"20px"}}>
                                <div className="content  custome-content">
                                  <p style={{ fontSize: "18px" }}><b>BANK</b><span className="dot">:</span><span className="a1a1">AXIS BANK</span></p>
                                  {/* <p><b>BANK</b><span className="dot">:</span><span className="a1a1">AXIS BANK</span></p> */}
                                  <p style={{ fontSize: "18px" }}><b>A/C NAME </b><span className="dot">:</span><span className="a1a1">BALAJI RAJU </span></p>
                                  <p style={{ fontSize: "18px" }}><b>A/C NO </b><span className="dot">:</span><span className="a1a1"> 924010048582724</span></p>
                                  <p style={{ fontSize: "18px" }}><b>BRANCH</b><span className="dot">:</span><span className="a1a1" style={{ fontSize: "18px" }}> VEMBARPATTI</span></p>
                                  <p style={{ fontSize: "18px" }}><b>IFSC CODE</b><span className="dot">:</span><span className="a1a1">UTIB0001764</span></p>
                                </div>
                              </div>
                            </div>
                          // </div>
                        ))}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-xl-3">
                        <img src={require("../../assests/images/phonepay2.jpg")} height={360} style={{ marginTop: "20px",  width:"315px", objectFit: "cover" }}/>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-xl-3"> 
                         <img className="logo" src={require('../../assests/images/malligaipay.jpeg')} alt="QR Code" height={360}   style={{ marginTop: "20px",  width:"315px" , objectFit: "cover"}} /></div> 
                    
                    </div>
                    
                    

                   
                  </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
               
                <h3 className="payonline">PAY ONLINE
                </h3>
              
                <div className="img-pay" ><span ><img className="img-go" src={require('../../assests/images/Goog-Pay.jpg')} style={{ height: '25px' }} /></span><span className="wwww">  Google Pay : {banks.length&&banks[0].bankpno}</span></div>
                <div className="img-pay"><span className="img-come"><img src={require('../../assests/images/phonepe-logo.png')} style={{ height: '25px' }} /></span><span className="wwee">  Phone Pay : {banks.length&&banks[0].bankpno}</span></div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="dedede">
          <div className="container">
            <div className="appimage">
              <div className="row">
                {/* <div className="col-md-5 siii">
                  <div>
                    <ReactPlayer className='matrivideo' url='https://www.youtube.com/watch?v=HxtVLHGiMjA' />
                  </div>
                  <a href="https://play.google.com/store/apps/details?id=com.app.thirumagal.matrimony" target="_blank">
                    <h3 className="clicking">மல்லிகை App-ஐ டவுன்லோடு செய்ய இங்கே கிளிக் செய்யவும்.</h3>
                  </a>
                  <h3 className="clicking">மல்லிகை App-ஐ டவுன்லோடு செய்ய இங்கே கிளிக் செய்யவும்.</h3>

                  <a target="_blank">
                    <button type="button" class="btn btn-primary fir">Download app</button>
                    <img className="logo" style={{ marginTop: "15px" }} src={require('../../assests/images/playstore.png')} />
                  </a>
                </div> */}
                <div className="col-md-12 d-flex row freesubmit">
                  <div className="col-md-5">
                    <h3 style={{ color: "white", fontWeight: "bold", lineHeight: '30px' }}>
                      ஸ்ரீ மல்லிகை App-ஐ டவுன்லோடு செய்ய இங்கே கிளிக் செய்யவும்.
                    </h3>
                    <img
                      className="logo"
                      style={{ marginTop: "15px" }}
                      src={require("../../assests/images/playstore.png")}
                    />
                  </div>

                  <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                    {/* <h1 className="text-center bg-danger">QR Code</h1> */}
                    <img
                      className="logo qrscan2"
                      src={require("../../assests/images/appimage.png")}
                      alt="QR Code"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Backdrop
        sx={{ color: "#e9018b", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openspinner}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      </div>
    </>
  );
}

export default Homepage;
