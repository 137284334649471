import React, { useState, useEffect } from "react";
import { getservice } from "./../action/user";
import config from "../../lib/config";

function Services() {
  const [services, setServices]=useState([])
  const getUserData = async () => {
  var test =  await getservice();
  setServices(test.userValue);
  // console.log(test, "twinkle");
  }
  useEffect(() => {
    //logout(history)
    getUserData();
  }, []);
    return (
        <div>
             <section style={{"box-shadow":"0px 3px 23px 0px rgb(244, 246, 249)"}}>
        <div className="container about-us">
          <div className="contact-banner-heading text-center col-md-offset-4 col-md-3" style={{ marginTop:"40px"}}>
            <div id="triangle_left" />
            <p>Services</p>
            <div id="triangle_right" />
          </div>
        </div>
        {/*//services heading*/}
        {/*services images*/}
        <div className="servicesimages">
          <div className="container">
            <div className="row">
            {services.map(post=>(
              <div className="col-md-4 col-sm-6">
                <img className="images img-responsive1" src={config.API+"/images/user/"+post.image}  />
                <p>{post.title}</p>
              </div>    
               ))}
              
            </div>
          </div>
        </div>
      </section>
      <div className="w3layouts-footerdesign">
        <div className="footerdesign">
          <img src={ require('../../assests/images/bg.png') }  />
        </div>
        <div className="clearfix" />
      </div>
            </div>
     
     )
 }
 export default Services;
 