import React, { useState, useEffect } from "react";
import { getterms } from '../action/user';

function Privacy() {

    const [privacy_title, settitle] = useState()
    const [privacy_content, setcontent] = useState()
    const gettermsData = async () => {
        var test = await getterms();
        settitle(test.userValue.title);
        setcontent(test.userValue.content);
    };

    useEffect(() => {
        //logout(history)
        gettermsData();
    }, []);

    return (
        <div className="container static-page">
            <div className="content">
                <h2> Terms and Condition</h2>
                <p /><div className="cd-pricing-container padding-lr-zero xxl-16 xl-16 l-16 m-16 s-16 xs-16">
                    <div
                        dangerouslySetInnerHTML={{ __html: privacy_content }}
                    />


                    <ul className="cd-pricing-list cd-bounce-invert xxl-16 xl-16 l-16 m-16 s-16 xl-16">
                    </ul>
                </div>
                <p>&nbsp;</p>
                <p />
            </div>
        </div>

    );
}
export default Privacy;