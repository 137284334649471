import React from 'react';
import './side.css'
import Main from './spiderman'
import { getsitedata, logout } from "./../action/user";

function Side() {
  const onlogout = async () => {
    var test = await logout();
    window.location = "/";
  }

  return (
    <div className='row'>
      <div className='col-md-2'>
        <div className="sidebar">
          <a className="active" href='/myprofile'>என் சுயவிவரம்</a>
          <a href='/showCard'>நான் பார்த்த வரன்கள்</a>
          <a href='/anotherviewed'>என்னை பார்த்த வரன்கள்</a>
          {/* <a href='/saved'>விருப்பமான வரன்கள்</a> */}
          <a href='/membership'>உறுப்பினர் விவரங்கள்</a>
          <a onClick={onlogout}>வெளியேறு</a>
        </div>
      </div>
      <div className='col-md-10'>
        <div className="contents">
          <Main />
        </div>
      </div>
    </div>
  );
}
export default Side;