import React from "react";
import ReactDOM from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "./robot.css";

// const TEST_SITE_KEY = "6LfCkGghAAAAAEwbucuCOKXnHBeBvDspproaxYoQ";
const TEST_SITE_KEY = "6LcytE0qAAAAALoc2s7gQpTYvHY9ci-C7BKqmU-P";
// secret key - 6LcytE0qAAAAAA--MZWi_ZzS4j94323hc1xax-if

const DELAY = 1000;

export default class App extends React.Component {
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
            callback: "not fired",
            value: "[empty]",
            load: false,
            expired: "false"
        };
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ load: true });
        }, DELAY);
        console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
    }

    handleChange = value => {
        console.log("Captcha value:", value);
        this.setState({ value });
        // if value is null recaptcha expired
        if (value === null) this.setState({ expired: "true" });
    };

    asyncScriptOnLoad = () => {
        this.setState({ callback: "called!" });
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    };

    render() {
        const { value, callback, load, expired } = this.state || {};
        return (
            <div className="App1">
                {/* <h1>
                    <a
                        href="https://github.com/dozoisch/react-google-recaptcha"
                        target="_blank"
                    >
                        react-google-recaptcha
                    </a>
                    : 2.1.0
                </h1>
                <h2>
                    NOTE: initial load delayed <em>{DELAY / 1000}sec</em> to demonstrate
                    callback
                </h2>
                <h3>Recaptcha loaded callback: {callback}</h3>
                <h5>Recaptcha value: {value}</h5>
                <h5>Expired: {expired}</h5> */}
                {load && (
                    <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        theme="light"
                        ref={this._reCaptchaRef}
                        sitekey={TEST_SITE_KEY}
                        onChange={this.handleChange}
                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                    />
                )}
            </div>
        );
    }
}

